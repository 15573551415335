@charset "UTF-8";
.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}
.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}
.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}
.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  order: -1;
}
.order-last {
  order: 13;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}
.offset-1 {
  margin-left: 8.3333333333%;
}
.offset-2 {
  margin-left: 16.6666666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.3333333333%;
}
.offset-5 {
  margin-left: 41.6666666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.3333333333%;
}
.offset-8 {
  margin-left: 66.6666666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.3333333333%;
}
.offset-11 {
  margin-left: 91.6666666667%;
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
button {
  border: none;
}
button:hover {
  cursor: pointer;
}
.btn {
  border-radius: 25px;
  clear: both;
  text-transform: uppercase;
  width: auto;
  padding: 10px 15px;
  font-size: 24px;
  display: inline-block;
}
.btn--primary {
  background: #fff;
  color: #0f1f53;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}
.btn--blue {
  background: #0f1f53;
  color: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
@media (max-width: 600px) {
  .btn {
    padding: 5px 10px;
  }
}
header {
  width: 100%;
  position: fixed;
  z-index: 2;
}
.logo {
  width: 170px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 5px;
  transition: all 0.3s;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  z-index: 30;
}
.logo img {
  max-width: 100%;
}
.youtube {
  position: absolute;
  right: 5%;
  top: 10px;
  transition: all 0.3s;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  z-index: 30;
}
.snot1 {
  position: absolute;
  top: -160px;
  left: calc(50% - 1290px);
  z-index: 27;
  animation: 3s infinite shake 0.5s;
  object-fit: cover;
}
.snot2 {
  position: absolute;
  top: -160px;
  left: calc(50% - 890px);
  z-index: 24;
  animation: 3s infinite shake 1s;
  object-fit: cover;
}
.snot3 {
  position: absolute;
  top: -160px;
  left: calc(50% - 535px);
  z-index: 25;
  animation: 3s infinite shake 1.5s;
  object-fit: cover;
}
.snot4 {
  position: absolute;
  top: -160px;
  left: calc(50% - 515px);
  z-index: 26;
  animation: 3s infinite shake 2s;
  object-fit: cover;
}
.snot5 {
  position: absolute;
  top: -160px;
  left: calc(50% - 210px);
  z-index: 28;
  animation: 3s infinite shake 2.5s;
  object-fit: cover;
}
.snot6 {
  position: absolute;
  top: -160px;
  left: calc(50% - 85px);
  z-index: 21;
  animation: 3s infinite shake 3s;
  object-fit: cover;
}
.snot7 {
  position: absolute;
  top: -160px;
  left: calc(50% + 380px);
  z-index: 23;
  animation: 3s infinite shake 3.5s;
  object-fit: cover;
}
.snot8 {
  position: absolute;
  top: -160px;
  left: calc(50% + 670px);
  z-index: 22;
  animation: 3s infinite shake 4s;
  object-fit: cover;
}
@keyframes shake {
  50% {
    transform: scale(102%);
  }
}
.drop1 {
  position: absolute;
  top: -30px;
  left: calc(50% - 920px);
  z-index: 17;
  width: 30px;
  height: 100px;
  animation: 10s infinite dropdown 3s;
}
.drop2 {
  position: absolute;
  top: -30px;
  left: calc(50% - 720px);
  z-index: 14;
  width: 30px;
  height: 100px;
  animation: 10s infinite dropdown 6s;
}
.drop3 {
  position: absolute;
  top: -60px;
  left: calc(50% - 510px);
  z-index: 15;
  width: 30px;
  height: 100px;
  animation: 10s infinite dropdown 9s;
}
.drop4 {
  position: absolute;
  top: -20px;
  left: calc(50% - 250px);
  z-index: 16;
  width: 30px;
  height: 100px;
  animation: 10s infinite dropdown 12s;
}
.drop5 {
  position: absolute;
  top: -20px;
  left: calc(50% + 100px);
  z-index: 18;
  width: 30px;
  height: 100px;
  animation: 10s infinite dropdown 14s;
}
.drop6 {
  position: absolute;
  top: -60px;
  left: calc(50% + 360px);
  z-index: 10;
  width: 30px;
  height: 100px;
  animation: 10s infinite dropdown 16s;
}
.drop7 {
  position: absolute;
  top: -10px;
  left: calc(50% + 600px);
  z-index: 13;
  width: 30px;
  height: 100px;
  animation: 10s infinite dropdown 18s;
}
.drop8 {
  position: absolute;
  top: -50px;
  left: calc(50% + 860px);
  z-index: 12;
  width: 30px;
  height: 100px;
  animation: 10s infinite dropdown 20s;
}
@keyframes dropdown {
  50% {
    top: 0px;
    height: 140px;
    opacity: 0.8;
    width: 38px;
  }
}
@media (max-width: 500px) {
  header {
    height: 166px;
    background-size: auto 100%;
    background-position: 56% 0;
  }

  .youtube {
    top: 5px;
    top: -180px;
  }

  .snot1 {
    width: 427px;
    top: -180px;
    left: calc(50% - 1290px);
  }

  .snot2 {
    width: 340px;
    top: -180px;
  }

  .snot3 {
    width: 175px;
    top: -180px;
  }

  .snot4 {
    width: 405px;
    top: -120px;
  }

  .snot5 {
    width: 460px;
    top: -139px;
    left: calc(50% - 241px);
  }

  .snot6 {
    width: 487px;
    top: -120px;
    left: calc(50% - 123px);
  }

  .snot7 {
    width: 348px;
    top: -180px;
  }

  .snot8 {
    width: 385px;
    top: -180px;
  }

  .drop5 {
    left: calc(50% + 20px);
  }
}
@font-face {
  font-family: "RotondaC";
  src: url(fonts/RotondaC-Bold.a8c185d0.eot);
  src: local(RotondaC), url(fonts/RotondaC-Bold.a8c185d0.eot) format("embedded-opentype"), url(fonts/RotondaC-Bold.fd75bb94.woff) format("woff"), url(fonts/RotondaC-Bold.40f0fe78.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
* {
  box-sizing: border-box;
  font-family: RotondaC, Arial, sans-serif;
}
*:focus {
  outline: none;
}
html {
  scroll-behavior: smooth;
}
img {
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}
body {
  margin: 0;
  padding: 0;
  font-family: RotondaC, Arial, sans-serif;
  font-size: 18px;
  color: #fff;
  background: #101c4f url("img/head.4cdd85ac.webp") repeat top center;
  background-size: 100%;
  position: relative;
}
a {
  color: #fff;
  text-decoration: none;
}
.brands {
  width: 100%;
  margin: 0 auto;
  padding-top: 100px;
}
.slick-container-wrapper {
  width: 100%;
  position: relative;
}
.slick-container {
  max-width: 1600px;
  margin: auto;
  width: 100%;
}
.slick:not(.mixed-items, .additional-products) .slick-prev {
  left: 10px;
}
.slick:not(.mixed-items, .additional-products) .slick-next {
  right: 10px;
}
@media (min-width: 1600px) {
  .slick:not(.mixed-items, .additional-products, .choose-slime) {
    position: static;
  }

  .slick:not(.mixed-items, .additional-products) .slick-prev {
    left: 40px;
  }

  .slick:not(.mixed-items, .additional-products) .slick-next {
    right: 40px;
  }
}
.brand {
  position: relative;
  padding: 10px 0;
  transition: 0.3s;
  cursor: pointer;
}
.brand:hover {
  transform: scale(1.1);
}
.brand img {
  max-width: 100% !important;
  width: auto !important;
  margin: 0 auto;
}
.brand:after {
  content: "";
  position: absolute;
  width: 50%;
  height: 30%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  filter: blur(20px);
  z-index: -1;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.3s;
}
.brand:hover:after {
  opacity: 1;
}
.slick .slick-center .brand {
  transform: scale(1.1);
}
.slick .slick-center .brand:after {
  content: "";
  position: absolute;
  width: 50%;
  height: 30%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  filter: blur(20px);
  z-index: -1;
  border-radius: 50%;
  opacity: 1;
  transition: all 0.3s;
}
.crystal:after {
  background: #27b0ce;
}
.crunch:after {
  background: #ffd600;
}
.ninja:after {
  background: #44bd6d;
}
.butter:after {
  background: #ffd600;
}
.cream:after {
  background: #ffb6b1;
}
.jungle:after {
  background: #c34a00;
}
.cloud:after {
  background: #2e34c8;
}
.slime:after {
  background: #72bd44;
}
.clear:after {
  background: #ff9900;
}
.slick {
  padding: 0 50px;
}
.slick:active {
  padding: 0 50px;
  cursor: grabbing;
}
.slick-prev {
  z-index: 1;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background: url("img/brands/left.e1da4337.webp") no-repeat center center !important;
  width: 39px;
  height: 39px;
  transition: 0.3s;
}
.slick-prev:hover {
  background: url("img/brands/left-hover.1f723e68.webp") no-repeat center center !important;
  cursor: pointer;
}
.slick-next {
  z-index: 1;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background: url("img/brands/right.d5d36452.webp") no-repeat center center !important;
  width: 39px;
  height: 39px;
  transition: 0.3s;
}
.slick-next:hover {
  background: url("img/brands/right-hover.dfa2ec5f.webp") no-repeat center center !important;
  cursor: pointer;
}
.slick:not(#sync2) .slick-slide {
  margin-right: 5px;
  margin-left: 5px;
}
.slick:not(#sync2) .slick-list .videos .slick-prev {
  margin: 0 -5px;
}
.videos .slick-next {
  margin-right: 1.9%;
}
.videos {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.video {
  width: 100%;
  height: 726px;
  overflow: hidden;
}
.video video {
  width: 100%;
  min-width: 1920px;
  margin-left: 50%;
  transform: translateX(-50%);
}
#sync2 {
  padding: 0;
}
#sync2 .slick-next, #sync2 .slick-prev {
  display: none !important;
}
.soundwrap {
  position: absolute;
  top: 10px;
  left: 10px;
  opacity: 0.5;
}
@media (min-width: 1600px) {
  .soundwrap {
    top: 40px;
    left: 40px;
  }
}
.soundwrap:hover {
  cursor: pointer;
}
.soundwrap svg {
  display: block;
  position: relative;
  opacity: 0.5;
}
.volume {
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.volume-icon {
  width: 50px;
  height: 50px;
  background: url("img/volume-off-icon.4f9cf690.webp");
}
.volume input {
  display: none;
}
.volume input:checked + .volume-icon {
  background: url("img/volume-on-icon.73d41242.webp");
}
.slimerblock {
  width: 100%;
  height: auto;
  padding: 200px 0;
  text-align: center;
}
.slimertext {
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 70px;
}
.slimermixtext br {
  display: none;
}
.products {
  width: 100%;
  margin: 0 auto;
}
.products .slick-track {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
}
.product {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 0;
}
.product > img {
  max-width: 100% !important;
  width: auto !important;
  margin: 0 auto;
  transition: opacity 0.3s;
}
.product > span {
  display: block;
  text-align: center;
  font-size: 24px;
  margin-top: 20px;
  transition: opacity 0.3s;
}
.additional-products .product .buttons-overlay {
  background-color: transparent;
}
.additional-products .product:hover > img {
  opacity: 0.5;
}
.product:hover .buttons-overlay {
  top: 0%;
  opacity: 1;
}
.product:hover > img, .product:hover > span {
  opacity: 0.5;
}
.mixblock {
  width: 100%;
  height: auto;
  text-align: center;
  background: url("img/mix-bg.6eda8c58.webp") no-repeat top center;
  background-size: cover;
}
.mixblock img {
  max-width: 100%;
}
.mixtext {
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 70px;
}
.mixlogo-wrapper {
  position: relative;
  display: inline-block;
}
.mixlogo-wrapper::after {
  content: "";
  width: 310px;
  height: 307px;
  position: absolute;
  top: 70%;
  right: 80%;
  background: url("img/game/slime-pink.221d62f5.webp") no-repeat center center;
  background-size: contain;
}
@keyframes rotate {
  0% {
    transform: perspective(12em) rotateX(50deg) rotate(0deg) scale(1);
  }
  50% {
    transform: perspective(12em) rotateX(50deg) rotate(180deg) scale(1.1);
  }
  100% {
    transform: perspective(12em) rotateX(50deg) rotate(360deg) scale(1);
  }
}
.mixlogo2 {
  margin-left: -130px;
}
.mixbutton {
  width: 100%;
  margin-top: 70px;
}
.mixbutton a {
  border-radius: 20px;
  clear: both;
  text-transform: uppercase;
  width: auto;
  padding: 5px 10px;
  font-size: 24px;
  display: inline-block;
}
.steps {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.steps__wrapper {
  max-height: 100%;
  min-width: 100%;
  overflow: hidden;
}
.step {
  min-width: 100%;
  max-width: 100%;
  padding: 100px 0;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.step.active {
  background: url("img/mix-mainscreen-bg-new.01865978.webp") no-repeat center top;
  background-size: cover;
  align-items: center;
}
.step:nth-child(-n+4) {
  padding-top: 200px;
  padding-bottom: 200px;
}
.step.active .buttons {
  margin-top: 100px;
}
.choose-slime .slick-track {
  padding-top: 50px;
  padding-bottom: 100px;
}
.choose-slime .slick-slide {
  transition: all 0.3s;
  transform: scale(0.6);
}
.choose-slime .slick-slide {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.choose-slime .slick-slide.prev, .choose-slime .slick-slide.next {
  transform: scale(0.8);
  transition: all 0.3s;
  transform-origin: center left;
}
.choose-slime .slick-slide.next {
  transform-origin: center right;
}
.choose-slime .slick-slide.slick-center {
  transform: scale(1);
  transition: all 0.3s;
}
.choose-slime .slick-slide.slick-center::after {
  content: "";
  width: 70px;
  height: 70px;
  background-image: url("img/game/arrow.5848ffc3.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0px;
  transform: translateY(100%) translateX(100%) rotate(8.67deg);
  transform-origin: center;
  animation: float-arrow 2.5s infinite cubic-bezier(0.37, 0, 0.63, 1);
}
@keyframes float-arrow {
  0%, 100% {
    transform: translateY(100%) translateX(100%) rotate(8.67deg);
  }
  25% {
    transform: translateY(70%) translateX(100%) rotate(8.67deg);
  }
}
.choose-slime img {
  max-width: 100%;
  margin: auto;
}
#step-2 .buttons, #step-3 .buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto 0px;
}
#step-2 .buttons .btn:first-child, #step-3 .buttons .btn:first-child {
  margin-right: 300px;
}
#step-3 .step-grid {
  height: 477px;
}
.step-grid {
  position: relative;
}
.step-grid__slime {
  position: relative;
}
.droppable-item {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.droppable-item > img {
  position: absolute;
}
.droppable-item > img:not(.choosen-ps) {
  animation: added-item 1s;
}
@keyframes added-item {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  30% {
    transform: scale(0.9);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.9;
  }
  80% {
    transform: scale(0.95);
    opacity: 0.7;
  }
}
.draggable-item {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.draggable-item:hover {
  cursor: grab;
}
.draggable-item.taken {
  transform: scale(1.4);
  cursor: grabbing;
}
.step-grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas: "a b c c d e" "f g c c h i" "j k c c l m";
}
.step-grid img {
  max-width: 100%;
}
.step-grid > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.step-grid .step-grid__slime {
  grid-area: c;
  display: flex;
  align-items: center;
  justify-content: center;
}
#step-3 .step-grid {
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas: "a b c c d e" "f g c c h i";
}
.result-container {
  max-width: 700px;
  margin: 0 auto;
}
.mixed-item {
  padding: 0px 50px;
  margin-bottom: 50px;
}
.mixed-item__img {
  min-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.mixed-item__img img {
  transition: opacity 0.3s;
  max-height: 330px;
}
.mixed-item__img::after {
  content: "";
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M33 33L25.6807 25.6677L33 33ZM29.7368 15.8684C29.7368 19.5466 28.2757 23.074 25.6749 25.6749C23.074 28.2757 19.5466 29.7368 15.8684 29.7368C12.1903 29.7368 8.6628 28.2757 6.06197 25.6749C3.46113 23.074 2 19.5466 2 15.8684C2 12.1903 3.46113 8.6628 6.06197 6.06197C8.6628 3.46113 12.1903 2 15.8684 2C19.5466 2 23.074 3.46113 25.6749 6.06197C28.2757 8.6628 29.7368 12.1903 29.7368 15.8684V15.8684Z' stroke='%230F1F53' stroke-width='4' stroke-linecap='round'/%3E%3Cpath d='M15.8682 10.1577V15.8682M15.8682 15.8682V21.5788M15.8682 15.8682H21.5788M15.8682 15.8682H10.5' stroke='%230F1F53' stroke-width='4' stroke-linecap='square'/%3E%3C/svg%3E");
  background-size: contain;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
}
.mixed-item__info {
  margin-left: 40px;
  position: relative;
  overflow: hidden;
}
.mixed-item__info.links-opened .markets-links {
  transform: translateY(0%);
}
.mixed-item__info.links-opened .buy-btn {
  display: block;
  visibility: hidden;
}
.mixed-item__info.links-opened .mixed-item__info__text {
  visibility: hidden;
  transform: translateY(-100%);
}
.mixed-item__info__text {
  font-size: 36px;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 20px;
  transition: all 0.3s;
}
#step-4, #step-5 {
  background: url("img/game/mix-result-bg.67f7a432.webp") no-repeat center;
  background-size: cover;
  padding-bottom: 50px;
  padding-top: 50px;
}
#step-5 .mixed-item {
  padding: 0px;
}
.additional-products {
  margin-bottom: 50px;
}
.additional-products .product {
  padding: 0px 10px;
}
.slick.step-carousel {
  margin-top: 20px;
}
.images-modal {
  position: fixed;
  max-height: 700px;
  max-width: 580px;
  height: 975px;
  width: 1525px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  z-index: 11;
  background-color: #fff;
  border-radius: 60px;
  padding: 80px;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
  visibility: hidden;
  transition: all 0.4s;
}
.images-modal__content {
  position: static !important;
}
.images-modal__content .slick-prev {
  left: -60px;
}
.images-modal__content .slick-next {
  right: -60px;
}
@media (max-width: 1600px) {
  .step:nth-child(-n+4) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .mixlogo-wrapper::after {
    width: 210px;
    height: 207px;
  }
}
@media (max-width: 1200px) {
  .step-grid img {
    max-width: 80%;
  }

  #step-3 .step-grid {
    height: auto;
  }
}
@media (max-width: 850px) {
  .mixlogo-wrapper {
    max-width: 90%;
  }

  img.mixlogo {
    max-width: 500px;
  }

  img.mixlogo2 {
    max-width: 200px;
  }

  .result-container {
    max-width: 100%;
  }

  .mixed-item__info__text {
    font-size: 24px;
  }

  .mixed-item__info {
    text-align: left;
  }
}
@media (min-width: 600px) {
  .slick.step-carousel {
    display: none;
  }
}
@media (max-width: 600px) {
  .step-grid {
    display: block;
  }
  .step-grid > div:not(.step-grid__slime) {
    display: none;
  }

  #step-2 .buttons .btn:first-child, #step-3 .buttons .btn:first-child {
    margin-right: unset;
  }

  #step-2 .buttons, #step-3 .buttons {
    width: 90%;
  }

  .mixlogo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img.mixlogo {
    max-width: 100%;
  }

  img.mixlogo2 {
    margin-left: 0px;
    max-width: 120px;
  }

  .step.active .buttons {
    margin-top: 50px;
  }

  .choose-slime .slick-track {
    padding-top: 0px;
    padding-bottom: 50px;
  }

  .choose-slime .slick-prev, .choose-slime .slick-next {
    top: calc(50% - 25px);
  }

  .step-carousel .slick-track {
    display: flex;
    align-items: center;
  }
  .step-carousel .slick-slide {
    padding: 10px;
  }

  .choose-slime .slick-slide.slick-center::after {
    width: 50px;
    height: 50px;
  }

  .result-container .slick-prev {
    left: 10px;
  }
  .result-container .slick-next {
    right: 10px;
  }
}
@media (max-width: 430px) {
  .droppable-item {
    width: 80%;
    margin: auto;
    top: unset;
    left: unset;
  }

  .mixlogo-wrapper::after {
    display: none;
  }

  .mixed-item__info {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mixed-item__info__text {
    font-size: 16px;
  }

  .mixed-item__info {
    margin-left: 0px;
    padding-left: 0px;
  }

  .btn {
    font-size: 20px;
  }

  .mixed-item__info .btn {
    margin-bottom: 10px;
  }
}
.labsblock {
  width: 100%;
  height: auto;
  padding: 200px 0;
}
.labstext {
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 70px;
}
.labstext br {
  display: none;
}
.labstypes-wrapper {
  padding: 0 5%;
}
.labstypes {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 100px;
}
.labstypes .labstype {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 0;
  margin: 0 10px;
  text-align: center;
}
@media (min-width: 1400px) {
  .labstypes .labstype {
    min-width: 135px;
  }
}
.labstypes .labstype img {
  margin: auto;
  max-width: 100%;
}
.labstypes .labstype span {
  white-space: nowrap;
}
.labs {
  width: 100%;
  margin: 0 auto;
}
.labs .slick-track {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
}
.lab {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 0;
  overflow: hidden;
}
.labs .lab:hover .buttons-overlay {
  top: 0%;
  opacity: 1;
}
.labs .lab > img, .labs .lab > span {
  opacity: 1;
  transition: opacity 0.3s;
}
.labs .lab:hover > img, .labs .lab:hover > span {
  opacity: 0.5;
}
.lab img {
  max-width: 100% !important;
  width: auto !important;
  margin: 0 auto;
}
.lab span {
  display: block;
  text-align: center;
  font-size: 22px;
  margin-top: 20px;
}
/*.lab:hover{opacity:0.5}
.lab:before{content:'';position:absolute;width:100%;height:100%;top:50%;left:50%;transform:translateX(-50%) translateY(-50%);z-index:2;opacity:0;transition:all 0.3s}
.lab:hover:before{opacity:1}
 .lab:after{content:'Смотреть';position:absolute;top:50%;left:50%;transform:translateX(-50%) translateY(-50%);z-index:3;background:#fff;color:#0F1F53;border-radius:20px;opacity:0;transition:all 0.3s;text-transform:uppercase;padding:5px 10px} */
.lab:hover:after {
  opacity: 1;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.section {
  height: 100vh;
  padding-top: 266px;
  overflow: hidden;
  position: relative;
  background: #98dbef;
  background-size: cover;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}
.bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  margin: 0;
}
.bubbles > li {
  position: absolute;
  list-style: none;
  display: block;
  text-align: center;
  width: 100px;
  padding: 10px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid rgba(25, 136, 146, 0.2);
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: inset -3px 1px 10px rgba(37, 47, 141, 0.5), inset 0 0 35px rgba(50, 100, 100, 0.2);
  -webkit-animation: float 25s infinite;
  animation: float 25s infinite;
  transition-timing-function: linear;
  bottom: -160px;
}
.bubbles > li:after {
  display: block;
  content: "";
  height: 8px;
  width: 11px;
  transform: rotate(40deg);
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  position: absolute;
  top: 20%;
  right: 20%;
}
.bubbles > li:before {
  display: block;
  content: "";
  height: 15px;
  width: 20px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  position: absolute;
  bottom: 20%;
  left: 20%;
  transform: rotate(40deg);
}
.bubbles > li:nth-child(1) {
  left: 10%;
}
.bubbles > li:nth-child(2) {
  left: 20%;
  transform: scale(0.5, 0.5);
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 17s;
  animation-duration: 17s;
}
.bubbles > li:nth-child(3) {
  left: 25%;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}
.bubbles > li:nth-child(4) {
  left: 40%;
  transform: scale(0.3, 0.3);
  -webkit-animation-duration: 22s;
  animation-duration: 22s;
  background-color: rgba(255, 255, 255, 0.25);
}
.bubbles > li:nth-child(5) {
  left: 70%;
  transform: scale(0.4, 0.4);
}
.bubbles > li:nth-child(6) {
  left: 80%;
  transform: scale(0.6, 0.6);
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  background-color: rgba(255, 255, 255, 0.1);
}
.bubbles > li:nth-child(7) {
  left: 32%;
  transform: scale(0.8, 0.8);
  -webkit-animation-delay: 7s;
  animation-delay: 7s;
}
.bubbles > li:nth-child(8) {
  left: 55%;
  transform: scale(0.2, 0.2);
  -webkit-animation-delay: 15s;
  animation-delay: 15s;
  -webkit-animation-duration: 40s;
  animation-duration: 40s;
}
.bubbles > li:nth-child(9) {
  left: 25%;
  transform: scale(0.1, 0.1);
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 40s;
  animation-duration: 40s;
}
.bubbles > li:nth-child(10) {
  left: 85%;
  transform: scale(1, 1);
  -webkit-animation-delay: 11s;
  animation-delay: 11s;
}
.bubbles > li:nth-child(11) {
  left: 85%;
  transform: scale(0.5, 0.5);
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.bubbles > li:nth-child(12) {
  left: 45%;
  transform: scale(0.4, 0.4);
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
  -webkit-animation-duration: 40s;
  animation-duration: 40s;
}
.bubbles > li:nth-child(13) {
  left: 15%;
  transform: scale(0.6, 0.6);
  -webkit-animation-delay: 7s;
  animation-delay: 7s;
}
.bubbles > li:nth-child(14) {
  left: 15%;
  transform: scale(0.9, 0.9);
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-duration: 15s;
  animation-duration: 15s;
}
.bubbles > li:nth-child(15) {
  left: 86%;
  transform: scale(0.3, 0.3);
  -webkit-animation-delay: 20s;
  animation-delay: 20s;
  -webkit-animation-duration: 22s;
  animation-duration: 22s;
}
.bubbles > li:nth-child(16) {
  left: 34%;
  transform: scale(1, 1);
  -webkit-animation-delay: 7s;
  animation-delay: 7s;
  -webkit-animation-duration: 6s;
  animation-duration: 6s;
}
.bubbles > li:nth-child(17) {
  left: 2%;
  transform: scale(0.7, 0.7);
  -webkit-animation-delay: 32s;
  animation-delay: 32s;
  -webkit-animation-duration: 11s;
  animation-duration: 11s;
}
.bubbles > li:nth-child(18) {
  left: 73%;
  transform: scale(0.4, 0.4);
  -webkit-animation-delay: 14s;
  animation-delay: 14s;
  -webkit-animation-duration: 14s;
  animation-duration: 14s;
}
.bubbles > li:nth-child(19) {
  left: 59%;
  transform: scale(0.8, 0.8);
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
}
.bubbles > li:nth-child(20) {
  left: 11%;
  transform: scale(0.3, 0.3);
  -webkit-animation-delay: 86s;
  animation-delay: 86s;
  -webkit-animation-duration: 28s;
  animation-duration: 28s;
}
.bubbles > li:nth-child(21) {
  left: 44%;
  transform: scale(0.9, 0.9);
  -webkit-animation-delay: 48s;
  animation-delay: 48s;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
}
.bubbles > li:nth-child(22) {
  left: 93%;
  transform: scale(0.7, 0.7);
  -webkit-animation-delay: 32s;
  animation-delay: 32s;
  -webkit-animation-duration: 21s;
  animation-duration: 21s;
}
.bubbles > li:nth-child(23) {
  left: 31%;
  transform: scale(0.5, 0.5);
  -webkit-animation-delay: 60s;
  animation-delay: 60s;
  -webkit-animation-duration: 7s;
  animation-duration: 7s;
}
.bubbles > li:nth-child(24) {
  left: 69%;
  transform: scale(0.3, 0.3);
  -webkit-animation-delay: 48s;
  animation-delay: 8s;
  -webkit-animation-duration: 19s;
  animation-duration: 19s;
}
@-webkit-keyframes float {
  0% {
    bottom: -200px;
    margin-left: 0;
  }
  5% {
    margin-left: 0;
  }
  10% {
    margin-left: 10px;
  }
  15% {
    margin-left: 0;
  }
  20% {
    margin-left: 15px;
  }
  25% {
    margin-left: 0;
  }
  30% {
    margin-left: 10px;
  }
  35% {
    margin-left: 0;
  }
  40% {
    margin-left: 10px;
  }
  45% {
    margin-left: 0;
  }
  50% {
    margin-left: 20px;
  }
  55% {
    margin-left: 0;
  }
  60% {
    margin-left: 10px;
  }
  65% {
    margin-left: 0;
  }
  70% {
    margin-left: 10px;
  }
  75% {
    margin-left: 0;
  }
  80% {
    margin-left: 15px;
  }
  85% {
    margin-left: 0;
  }
  90% {
    margin-left: 10px;
  }
  95% {
    margin-left: 0;
  }
  100% {
    bottom: 120%;
    margin-left: 0;
  }
}
@keyframes float {
  0% {
    bottom: -120px;
    margin-left: 0;
  }
  5% {
    margin-left: 0;
  }
  10% {
    margin-left: 10px;
  }
  15% {
    margin-left: 0;
  }
  20% {
    margin-left: 15px;
  }
  25% {
    margin-left: 0;
  }
  30% {
    margin-left: 10px;
  }
  35% {
    margin-left: 0;
  }
  40% {
    margin-left: 10px;
  }
  45% {
    margin-left: 0;
  }
  50% {
    margin-left: 20px;
  }
  55% {
    margin-left: 0;
  }
  60% {
    margin-left: 10px;
  }
  65% {
    margin-left: 0;
  }
  70% {
    margin-left: 10px;
  }
  75% {
    margin-left: 0;
  }
  80% {
    margin-left: 15px;
  }
  85% {
    margin-left: 0;
  }
  90% {
    margin-left: 10px;
  }
  95% {
    margin-left: 0;
  }
  100% {
    bottom: 110%;
    margin-left: 0;
  }
}
@media (orientation: portrait), (max-width: 1400px) {
  .section {
    height: auto;
  }

  .section:not(.section-1) {
    padding: 200px 0;
  }
}
@media (max-width: 992px) {
  .section:not(.section-1) {
    padding: 100px 0;
  }
}
.section_content {
  height: 100%;
  position: relative;
  z-index: 1;
  padding: 20px 0 0;
}
@media (max-width: 768px) {
  .section_content {
    padding: 20px 25px 0;
  }
}
.img-fluid {
  max-width: 100%;
  max-height: 100%;
  width: auto;
}
.section_bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.floating-animate {
  -webkit-animation: floating 6s ease-in-out infinite;
  animation: floating 6s ease-in-out infinite;
}
@keyframes floating_cat {
  0% {
    transform: translateX(-5%) translateY(-5%);
  }
}
.section-1 {
  position: relative;
  z-index: 1;
}
.section-1 .row {
  height: 100%;
  position: relative;
}
.section-1 .row .col-sm-6 {
  max-height: 100%;
}
.section-1 .row .img-fluid {
  max-height: 100%;
  max-width: 100%;
}
.section-1 .row .img-fluid.main-text {
  max-height: 32%;
}
.section-1 .row .img-fluid.main-aquarium {
  max-height: calc(100% - 32%);
  position: relative;
  left: 14%;
}
.section-1 .row .img-fluid.main-right {
  position: relative;
  top: -5%;
  right: 5%;
}
.section-1 .star {
  position: absolute;
  width: 71px;
  height: 71px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
@media (max-width: 1200px) {
  .section-1 .star {
    width: 40px;
    height: 40px;
  }
}
.section-1 .star:nth-child(1) {
  top: 15%;
  left: -1%;
  background-image: url("img/aqua/blue-star-1.fc942d71.webp");
}
.section-1 .star:nth-child(2) {
  top: 83%;
  left: 3%;
  background-image: url("img/aqua/yellow-star-1.3b75480b.webp");
}
.section-1 .star:nth-child(3) {
  top: 61%;
  left: 7%;
  background-image: url("img/aqua/blue-star-2.251bfe5d.webp");
}
.section-1 .star:nth-child(4) {
  top: 28%;
  left: 13%;
  background-image: url("img/aqua/yellow-star-2.74c1b621.webp");
}
.section-1 .star:nth-child(5) {
  top: 70%;
  left: 19%;
  background-image: url("img/aqua/yellow-star-3.6510f662.webp");
}
.section-1 .star:nth-child(6) {
  top: 12%;
  left: 78%;
  background-image: url("img/aqua/yellow-star-4.04662ce5.webp");
}
.section-1 .star:nth-child(7) {
  top: 82%;
  left: 82%;
  background-image: url("img/aqua/blue-star-3.d450a57b.webp");
}
.section-1 .star:nth-child(8) {
  top: 44%;
  left: 89%;
  background-image: url("img/aqua/yellow-star-5.98dfbd7d.webp");
}
.section-1 .star:nth-child(9) {
  top: 5%;
  left: 97%;
  background-image: url("img/aqua/blue-star-4.e27b71fd.webp");
}
.section-1 .star:nth-child(10) {
  top: 76%;
  left: 98%;
  background-image: url("img/aqua/yellow-star-6.e3bd5c99.webp");
}
@media (max-width: 576px) {
  .section-1 {
    height: 100vh;
  }

  .section-1 .col-sm-6.text-right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .section-1 .col-sm-6.text-right .main-aquarium {
    max-height: 38%;
    transform: translateX(10%);
    left: unset;
  }

  .section-1 .col-sm-6.text-right .main-text {
    max-height: 19%;
    transform: translateX(-25%);
  }

  .section-1 .col-sm-6.text-left {
    position: absolute;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .section-1 .col-sm-6.text-left .img-fluid.main-right {
    position: absolute;
    max-height: 61%;
    z-index: -1;
    top: unset;
    bottom: 19%;
    right: unset;
  }
}
.aqualogo {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
}
.aqualogo img {
  max-width: 100%;
  width: auto;
}
.planetblock {
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.planetblock span {
  color: #662483;
  white-space: nowrap;
  font-size: 24px;
}
.planeta {
  transition: all 0.3s;
}
.planeta:hover {
  filter: drop-shadow(0px 0px 20px rgba(255, 214, 0, 0.25));
  -webkit-filter: drop-shadow(0px 0px 20px rgba(255, 214, 0, 0.25));
}
.strelka {
  animation: floating 3s ease-in-out infinite;
}
@keyframes floating {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
footer {
  width: 100%;
  text-align: center;
  padding: 280px 0px 0px;
}
.footerphone {
  font-size: 36px;
}
.footertext {
  font-size: 24px;
}
.footerbutton {
  width: 100%;
  margin-top: 40px;
}
.footerbutton a {
  background: #fff;
  color: #0f1f53;
  border-radius: 20px;
  clear: both;
  text-transform: uppercase;
  width: auto;
  padding: 5px 10px;
  font-size: 24px;
}
.footersocial {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 80px;
}
.footersocial a {
  margin: 0 50px;
}
.footer-header {
  font-size: 36px;
  line-height: 43px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 80px;
}
.footer-slimes {
  position: absolute;
  left: 0px;
  width: 100%;
}
.footer-slimes img {
  max-width: 100%;
  height: auto;
  display: block;
}
@media (max-width: 768px) {
  .soundwrap {
    left: 3%;
  }

  .strelka {
    width: 40px;
  }

  .planeta {
    width: 120px;
  }

  .labstypes .labtype {
    font-size: 18px;
  }

  .lab span {
    font-size: 18px;
  }

  .brandsblock {
    background-size: cover;
  }

  .slimerblock {
    background-size: cover;
  }

  footer {
    padding: 100px 5% 0px;
  }
}
@media (max-width: 576px) {
  .strelka {
    width: 20px;
  }

  .planeta {
    width: 80px;
  }

  .section.section-1 {
    padding-top: 150px;
  }

  .section.section-1 .section_content {
    padding-bottom: 100px;
  }
}
@media (max-width: 500px) {
  header {
    height: 166px;
    background-size: auto 100%;
    background-position: 56% 0;
  }

  .logo {
    left: 30%;
    width: 150px;
  }

  .logo img {
    width: 100%;
  }

  .youtube {
    right: 10%;
    top: 5px;
    width: 50px;
  }

  .youtube img {
    width: 100%;
  }

  .video {
    max-height: 500px;
  }

  .video video {
    min-width: 1322px;
  }

  .slimerlogo {
    max-width: 260px;
  }

  .slimerblock, .labsblock {
    padding: 120px 0px;
  }

  .slimertext {
    font-size: 26px;
  }

  .mixtext br {
    display: initial;
  }

  .mixlogo {
    max-width: 260px;
  }

  .mixlogo2 {
    max-width: 120px;
  }

  .mixtext {
    font-size: 26px;
  }

  .labstext {
    font-size: 26px;
  }

  .labstext br {
    display: initial;
  }

  .text {
    width: 200px;
  }

  .footer-header, .footerphone {
    font-size: 24px;
    line-height: 28px;
  }

  .footertext {
    font-size: 20px;
  }

  .footer-slimes {
    overflow: hidden;
  }
  .footer-slimes img {
    height: 92px;
    width: auto;
    max-width: unset;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}
@media (max-width: 375.9px) {
  .video {
    width: 100%;
    overflow: hidden;
  }

  .video video {
    width: 100%;
    min-width: unset;
    margin-left: 0px;
    transform: unset;
  }
}
body.shop-modal-opened, body.images-modal-opened {
  overflow: hidden;
}
body.shop-modal-opened .shop-modal, body.images-modal-opened .images-modal {
  visibility: visible;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
body.shop-modal-opened .shop-modal__overlay, body.images-modal-opened .images-modal__overlay {
  display: block;
}
.shop-modal__overlay, .images-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  display: none;
  opacity: 0.5;
  z-index: 10;
}
.buttons-overlay {
  position: absolute;
  top: 100%;
  left: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1;
}
.soon {
  color: #0F1F53;
  font-size: 20px;
  text-align: center;
}
.next-cat, .prev-cat {
  position: absolute;
  width: 40px;
  height: 40px;
  top: calc(50% - 20px);
  background-size: contain;
  display: block;
  z-index: 1;
}
.next-cat {
  background: url("img/brands/right.b5944fb1.png") no-repeat center;
  right: -50px;
}
.next-cat:hover {
  background: url("img/brands/right-hover.29c368ac.png") no-repeat center;
}
.prev-cat {
  background: url("img/brands/left.c4a68169.png") no-repeat center;
  left: -50px;
}
.prev-cat:hover {
  background: url("img/brands/left-hover.eda7d8d4.png") no-repeat center;
}
.shop-modal {
  position: fixed;
  max-height: 90vh;
  max-width: calc(90vw - 50px);
  height: 975px;
  width: 1525px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  z-index: 11;
  background-color: #fff;
  border-radius: 60px;
  padding: 60px 90px 90px;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
  visibility: hidden;
  transition: all 0.4s;
}
.shop-modal__content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.shop-modal__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 80%;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 0px;
}
.shop-modal__logo {
  min-width: 200px;
  max-width: 200px;
  margin-right: 30px;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.shop-modal__logo--animate {
  transform: translateX(0%);
  opacity: 1;
}
.shop-modal__logo img {
  max-width: 100%;
}
.shop-modal__description {
  color: #0F1F53;
  transform: translateX(50%);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.shop-modal__description--animate {
  transform: translateX(0%);
  opacity: 1;
}
.shop-modal__products {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  flex: 1 1 auto;
  overflow-y: auto;
  justify-content: center;
  padding-right: 20px;
}
.shop-modal__product {
  position: relative;
  width: calc(20% - 30px);
  margin: 0px 15px 30px;
  overflow: hidden;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}
.shop-modal__product--animate {
  transform: scale(1);
  opacity: 1;
}
.shop-modal__product__img {
  transition: opacity 0.3s;
  position: relative;
}
.shop-modal__product__img img {
  max-width: 100%;
  margin: auto;
}
.shop-modal__product__title {
  color: #797979;
  font-size: 16px;
  text-align: center;
  transition: opacity 0.3s;
}
.shop-modal__product:hover .shop-modal__product__img, .shop-modal__product:hover .shop-modal__product__title, .shop-modal__product:focus .shop-modal__product__img, .shop-modal__product:focus .shop-modal__product__title {
  opacity: 0.5;
  z-index: -1;
}
.shop-modal__product:hover .buttons-overlay, .shop-modal__product:focus .buttons-overlay {
  top: 0%;
  opacity: 1 !important;
}
.close-modal {
  position: absolute;
  top: 40px;
  right: 40px;
  color: #0F1F53;
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-x-lg' viewBox='0 0 16 16'%3E%3Cpath d='M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}
.markets-links {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  transform: translateY(100%);
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
}
.buy-btn, .market-link, .close-links, .show-btn {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.market-link:not(:last-child) {
  margin-bottom: 10px;
}
.market-link {
  max-height: 47px;
  min-height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.market-link img {
  max-height: 100%;
  width: auto;
  margin: auto;
}
.buttons-overlay.links-opened .markets-links {
  transform: translateY(0%);
}
.buttons-overlay.links-opened .buy-btn {
  display: none;
}
.simple-bar-container {
  min-height: 1px;
  flex: 1;
}
.simple-bar-container .simplebar-track.simplebar-vertical {
  width: 13px;
  background: #F4F4F4;
  border-radius: 11px;
}
.simple-bar-container .simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  border-radius: 11px;
  background: #C4C4C4;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}
@media (max-width: 600px) {
  .market-link {
    max-height: 37px;
    min-height: 37px;
  }
}
@media (max-width: 430px) {
  .market-link {
    max-height: 33px;
    min-height: 33px;
  }
}
@media (max-width: 1500px) {
  .shop-modal {
    padding: 50px;
  }
  .shop-modal__product {
    width: calc(25% - 30px);
  }
}
@media (max-width: 1200px) {
  .shop-modal {
    border-radius: 20px;
  }
  .shop-modal__product {
    width: calc(33.333% - 30px);
  }
  .shop-modal__header {
    max-width: 100%;
  }

  .close-modal {
    top: 20px;
    right: 20px;
  }
}
@media (max-width: 768px) {
  .shop-modal {
    padding: 20px;
  }
  .shop-modal__product {
    width: calc(50% - 30px);
  }
}
@media (max-width: 600px) {
  .next-cat, .prev-cat {
    top: 60px;
  }

  .next-cat {
    right: 10px;
  }

  .prev-cat {
    left: 10px;
  }

  .shop-modal {
    padding: 20px;
    border-radius: 0px;
    width: 100%;
    height: 100%;
    max-width: unset;
    max-height: unset;
  }
  .shop-modal__header {
    flex-direction: column;
  }
  .shop-modal__product {
    width: calc(50% - 15px);
    margin: 0px 15px 30px 0px;
  }
  .shop-modal__product__title {
    font-size: 14px;
    line-height: 16px;
  }
  .shop-modal__logo {
    margin-right: 0px;
  }
  .shop-modal__description {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
  }

  .market-link {
    max-width: 95%;
  }
  .market-link img {
    max-width: 100%;
  }
}
.modal.aqua .modal-content {
  background-color: #ffffff;
}
.modal.aqua input, .modal.aqua textarea {
  max-width: 100%;
  width: 100%;
  border: none;
  border-bottom: 2px solid #292456;
  font-size: 18px;
}
.modal.aqua input:focus, .modal.aqua textarea:focus {
  border: none;
  border-bottom: 2px solid #292456;
  outline: none;
}
.modal.aqua input {
  margin-bottom: 20px;
}
.modal.aqua input:last-child {
  margin-bottom: 0px;
}
.modal.aqua textarea {
  height: 100%;
}
.modal.aqua .form-footer {
  margin-top: 20px;
}
.modal.aqua .checkbox {
  font-size: 12px;
  margin-bottom: 0px;
  color: #292456;
}
.modal.aqua .checkbox input[type=checkbox] {
  display: none;
}
.modal.aqua .checkbox input[type=checkbox]:checked + .checkbox-icon::after {
  display: block;
}
@media (max-width: 768px) {
  .modal.aqua .checkbox {
    margin-bottom: 16px;
  }
}
.modal.aqua .checkbox .checkbox-icon {
  min-width: 26px;
  min-height: 26px;
  border: 3px solid #292456;
  box-sizing: border-box;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
}
.modal.aqua .checkbox .checkbox-icon::after {
  content: "";
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #292456;
  display: none;
}
.modal.aqua .close-modal {
  background-color: transparent;
  top: 20px;
  right: 20px;
}
.response {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  max-width: 80%;
  top: 50%;
  background: #292456;
  margin: auto;
  transform: translateX(0%) translateY(-50%);
  left: 50%;
  color: white;
  padding: 30px;
  border-radius: 10px;
  transition: all 0.5s;
}
.response.show {
  visibility: visible;
  opacity: 1;
  transform: translateX(-50%) translateY(-50%);
}
.checkbox-icon.error {
  box-shadow: 0px 0px 5px 3px red;
}
.modal.aqua .modal-content {
  border-radius: 23px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
  padding: 80px;
}
#subscribe-form .checkbox {
  margin-bottom: 20px;
}
#subscribe-form .modal-header {
  margin: 0px 0px 20px;
  padding: 0px;
  border-bottom: none;
}
#subscribe-form .modal-title {
  color: #0f1f53;
  margin: 0px;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
}
@media (max-width: 400px) {
  #subscribe-form .modal-title {
    font-size: 14px;
    line-height: 16px;
  }
}
@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 860px;
  }
}
@media (max-width: 992px) {
  .modal.aqua .modal-content {
    border-radius: 23px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
    padding: 50px;
  }
}
@media (max-width: 400px) {
  .modal.aqua input, .modal.aqua textarea {
    font-size: 16px;
  }
}
ul.rays {
  position: absolute;
  left: -10em;
  right: -10em;
  top: -100%;
  bottom: -300px;
  margin: auto;
  padding: 0;
  width: 0;
  height: 0;
  list-style: none;
  opacity: 0.2;
  filter: blur(6px);
}
ul.rays.left {
  animation: 40s rotate-l infinite linear;
}
ul.rays.right {
  animation: 30s rotate-r infinite linear;
}
.rays > li,
.rays > li:before,
.rays > li:after {
  position: absolute;
  border: 0 solid transparent;
  border-width: 1.2em 24em;
  border-color: transparent white;
  width: 0;
  height: 0;
  font-size: 60px;
}
.rays > li {
  left: -24em;
  top: 40%;
  margin-top: -1.2em;
  transform: rotate(0.1deg);
}
.rays > li:before,
.rays > li:after {
  left: -24em;
  top: -1.2em;
  display: block;
  content: "";
}
.rays > li:before {
  transform: rotate(60deg);
}
.rays > li:after {
  transform: rotate(-60deg);
}
.rays > li:nth-child(2) {
  transform: rotate(12deg);
}
.rays > li:nth-child(2),
.rays > li:nth-child(2):before,
.rays > li:nth-child(2):after {
  border-color: transparent white;
}
.rays > li:nth-child(3) {
  transform: rotate(37deg);
}
.rays > li:nth-child(3),
.rays > li:nth-child(3):before,
LI:nth-child(3):after {
  border-color: transparent #e6f7ff;
}
.rays > li:nth-child(4) {
  transform: rotate(55deg);
}
.rays > li:nth-child(4),
.rays > li:nth-child(4):before,
.rays > li:nth-child(4):after {
  border-color: transparent white;
}
@keyframes rotate-l {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate-r {
  100% {
    transform: rotate(-360deg);
  }
}
.rays:before {
  background: conic-gradient(rgba(255, 255, 255, 0.42), rgba(255, 255, 255, 0.45), rgba(255, 255, 255, 0.69), rgba(87, 199, 255, 0.01), rgba(230, 247, 255, 0.22), rgba(240, 250, 255, 0.11), rgba(255, 255, 255, 0.53), rgba(255, 255, 255, 0.97), rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.73), rgba(255, 255, 255, 0.77), rgba(133, 214, 255, 0.35), rgba(148, 219, 255, 0.99), rgba(255, 255, 255, 0.7), rgba(250, 253, 255, 0.66), rgba(240, 250, 255, 0.07), rgba(255, 255, 255, 0.49), rgba(26, 179, 255, 0.39), rgba(230, 247, 255, 0.57), rgba(255, 255, 255, 0.27), rgba(82, 197, 255, 0.73), white, rgba(51, 187, 255, 0.53), rgba(255, 255, 255, 0.42), rgba(255, 255, 255, 0.45), rgba(255, 255, 255, 0.69), rgba(87, 199, 255, 0.01), rgba(230, 247, 255, 0.22), rgba(240, 250, 255, 0.11), rgba(255, 255, 255, 0.53), rgba(255, 255, 255, 0.97), rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.73), rgba(255, 255, 255, 0.77), rgba(133, 214, 255, 0.35), rgba(148, 219, 255, 0.99), rgba(255, 255, 255, 0.7), rgba(250, 253, 255, 0.66), rgba(240, 250, 255, 0.07), rgba(255, 255, 255, 0.49), rgba(26, 179, 255, 0.39), rgba(230, 247, 255, 0.57), rgba(255, 255, 255, 0.27), rgba(82, 197, 255, 0.73), white);
}
.rays:after {
  background: conic-gradient(rgba(255, 255, 255, 0.11), rgba(10, 173, 255, 0.91), rgba(255, 255, 255, 0.41), rgba(255, 255, 255, 0.81), rgba(255, 255, 255, 0.52), rgba(245, 252, 255, 0.38), rgba(204, 238, 255, 0.04), rgba(56, 189, 255, 0.61), rgba(128, 213, 255, 0.74), rgba(255, 255, 255, 0.92), rgba(214, 241, 255, 0.29), rgba(41, 184, 255, 0.9), rgba(255, 255, 255, 0.49), rgba(255, 255, 255, 0.29), rgba(199, 236, 255, 0.48), rgba(138, 216, 255, 0.41), rgba(255, 255, 255, 0.37), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.09), rgba(5, 172, 255, 0.95), rgba(255, 255, 255, 0.49), rgba(255, 255, 255, 0.91), rgba(255, 255, 255, 0.11), rgba(10, 173, 255, 0.91), rgba(255, 255, 255, 0.41), rgba(255, 255, 255, 0.81), rgba(255, 255, 255, 0.52), rgba(245, 252, 255, 0.38), rgba(204, 238, 255, 0.04), rgba(56, 189, 255, 0.61), rgba(128, 213, 255, 0.74), rgba(255, 255, 255, 0.92), rgba(214, 241, 255, 0.29), rgba(41, 184, 255, 0.9), rgba(255, 255, 255, 0.49), rgba(255, 255, 255, 0.29), rgba(199, 236, 255, 0.48), rgba(138, 216, 255, 0.41), rgba(255, 255, 255, 0.37), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.09), rgba(5, 172, 255, 0.95), rgba(255, 255, 255, 0.49));
  animation-duration: 3.6s;
  animation-direction: reverse;
}
@keyframes rot {
  to {
    transform: rotate(1turn);
  }
}
@media (max-width: 400px) {
  ul.rays {
    bottom: -200px;
  }
}
html {
  height: 100%;
}
body.taplink-page {
  min-height: 100%;
  height: 100%;
}
.taplinks-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}
.taplinks {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.taplinks .btn {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.taplinks .btn img {
  margin-right: 10px;
  max-height: 30px;
}
.taplinks .btn:last-child {
  margin-bottom: 0px;
}
@media (max-width: 600px) {
  .taplinks .btn {
    font-size: 12px;
    margin-bottom: 20px;
    padding: 15px 10px;
  }
  .taplinks .btn img {
    margin-right: 10px;
    max-height: 20px;
  }
  .taplinks .btn:last-child {
    margin-bottom: 0px;
  }
}
.grecaptcha-badge {
  visibility: hidden;
}
[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  max-width: 100%; /* Not required for horizontal scroll to trigger */
  max-height: 100%; /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.simplebar-content:before,
.simplebar-content:after {
  content: ' ';
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: '';
  background: black;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/*# sourceMappingURL=index.8f04f8d2.css.map */
