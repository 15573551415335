body.shop-modal-opened, body.images-modal-opened {
    overflow: hidden;
}
body.shop-modal-opened .shop-modal, body.images-modal-opened .images-modal {
    visibility: visible;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}
body.shop-modal-opened .shop-modal__overlay, body.images-modal-opened .images-modal__overlay {
    display: block;
}
.shop-modal__overlay, .images-modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    display: none;
    opacity: 0.5;
    z-index: 10;
}
.buttons-overlay {
    position: absolute;
    top: 100%;
    left: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .3s;
    z-index: 1;
    // background-color: rgba(255, 255, 255, 0.5);

}
.soon {
    color: #0F1F53;
    font-size: 20px;
    text-align: center;
}
.next-cat, .prev-cat {
    position: absolute;
    width: 40px;
    height: 40px;
    top: calc(50% - 20px);
    background-size: contain;
    display: block;
    z-index: 1;
}
.next-cat {
    background: url('url:../img/brands/right.png') no-repeat center;
    right: -50px;
    &:hover{
        background: url('../img/brands/right-hover.png') no-repeat center;
    }
}
.prev-cat {
    background: url('url:../img/brands/left.png') no-repeat center;
    left: -50px;
    &:hover{
        background: url('../img/brands/left-hover.png') no-repeat center;
    }
}
.shop-modal {
    position: fixed;
    max-height: 90vh;
    max-width: calc(90vw - 50px);
    height: 975px;
    width: 1525px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    z-index: 11;
    background-color: #fff;
    border-radius: 60px;
    padding: 60px 90px 90px;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
    // overflow: hidden;
    visibility: hidden;
    transition: all 0.4s;
    &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    &__header{
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 80%;
        margin: auto;
        margin-bottom: 20px;
        margin-top: 0px;
    }
    &__logo {
        min-width: 200px;
        max-width: 200px;
        margin-right: 30px;
        transform: translateX(-50%);
        opacity: 0;
        transition: all .3s cubic-bezier(0.34, 1.56, 0.64, 1);
        &--animate {
            transform: translateX(0%);
            opacity: 1;
        }
        img {max-width: 100%}
    }
    &__description {
        color: #0F1F53;
        transform: translateX(50%);
        opacity: 0;
        transition: all .3s cubic-bezier(0.34, 1.56, 0.64, 1);
        &--animate {
            transform: translateX(0%);
            opacity: 1;
        }
    }
    &__products {
        display: flex;
        flex-direction: row!important;
        flex-wrap: wrap!important;
        flex: 1 1 auto;
        overflow-y: auto;
        justify-content: center;
        padding-right: 20px;

    }
    &__product{
        position: relative;
        width: calc(20% - 30px);
        margin: 0px 15px 30px;
        overflow: hidden;
        transform: scale(0);
        opacity: 0;
        transition: all .3s cubic-bezier(0.34, 1.56, 0.64, 1);
        &--animate {
            transform: scale(1);
            opacity: 1;
        }
        &__img{
            transition: opacity .3s;
            position: relative;
            img{
                max-width: 100%;
                margin: auto;
            }
        } 
        &__title{
            color: #797979;
            font-size: 16px;
            text-align: center;
            transition: opacity .3s;
        }
        
        &:hover, &:focus {
            .shop-modal__product__img, .shop-modal__product__title {
                opacity: 0.5;
                z-index: -1;
            }
            .buttons-overlay {
                top: 0%;
                opacity: 1!important;
            }
        }
    }
}
.close-modal {
    position: absolute;
    top: 40px;
    right: 40px;
    color: #0F1F53;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-x-lg' viewBox='0 0 16 16'%3E%3Cpath d='M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
}


.markets-links{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    transform: translateY(100%);
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    // transform: translateY(-50%);
}

.buy-btn, .market-link, .close-links, .show-btn{
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}
.market-link:not(:last-child) {
    margin-bottom: 10px;
}
.market-link {
    max-height: 47px;
    min-height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    // transform: translateY(-50%);
    img {
        max-height: 100%;
        width: auto;
        margin: auto;
    }
}
.buttons-overlay.links-opened .markets-links{
    transform: translateY(0%);
}
.buttons-overlay.links-opened .buy-btn{
    display: none;
}
.simple-bar-container {
    // flex: 1 1 auto;
    min-height: 1px;
    flex: 1;
}
.simple-bar-container .simplebar-track.simplebar-vertical {
    width: 13px;
    background: #F4F4F4;
    border-radius: 11px;
    .simplebar-scrollbar::before{
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        border-radius: 11px;
        background: #C4C4C4;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    }
}
@media (max-width: 600px) {
    .market-link {
        max-height: 37px;
        min-height: 37px;
    }
}
@media (max-width: 430px) {
    .market-link {
        max-height: 33px;
        min-height: 33px;
    }
}

@media (max-width: 1500px) {
    .shop-modal {
        padding: 50px;
        &__product{
            width: calc(25% - 30px);
        }
    }
}
@media (max-width: 1200px) {
    .shop-modal {
        border-radius: 20px;
        &__product{
            width: calc(33.333% - 30px);
        }
        &__header{
            max-width: 100%;
        }
    }
    .close-modal {
        top: 20px;
        right: 20px;
    }
}
@media (max-width: 768px) {
    
    .shop-modal {
        padding: 20px;
        &__product{
            width: calc(50% - 30px);
        }
    }
}
@media (max-width: 600px) {
    .next-cat, .prev-cat {
        top: 60px;
    }
    .next-cat {
        right: 10px;
    }
    .prev-cat {
        left: 10px;
    }
    .shop-modal {
        padding: 20px;
        border-radius: 0px;
        width: 100%;
        height: 100%;
        max-width: unset;
        max-height: unset;
        &__header{
            flex-direction: column;
        }
        &__product{
            width: calc(50% - 15px);
            margin: 0px 15px 30px 0px;
            &__title{
                font-size: 14px;
                line-height: 16px;
            }
            // &:nth-child(odd){
            //     margin-right: 10px
            // }
        }
        &__logo {
            margin-right: 0px;
        }
        &__description {
            font-size: 14px;
            line-height: 16px;
            text-align: center;
        }
    }
    .market-link {
        max-width: 95%;
        img {
            max-width: 100%;
        }
    }
}
// @media (max-width: 375px) {
//     .shop-modal {
//         padding: 20px;
//         &__header{
//             flex-direction: column;
//         }
//         &__product{
//             width: calc(100% - 30px);
//         }
//         &__logo {
//             margin-right: 0px;
//         }
//         &__description {
//             font-size: 14px;
//             text-align: center;
//             line-height: 16px;
//         }
//     }
// }