.mixblock {
  width: 100%;
  height: auto;
  
  text-align: center;
  background: url('url:../img/mix-bg.png?as=webp') no-repeat top center;
  background-size: cover;
  img {
    max-width: 100%;
  }
}
.mixtext {
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 70px;
}
.mixlogo-wrapper {
  position: relative;
  display: inline-block;
  &::after {
    content: '';
    width: 310px;
    height: 307px;
    position: absolute;
    top: 70%;
    right: 80%;
    background: url('url:../img/game/slime-pink.png?as=webp') no-repeat center center;
    background-size: contain;
    // transform: perspective(12em) rotateX(50deg);
    // animation: rotate 1.5s linear infinite;

  }
  
  @keyframes rotate {
    0%{
      transform: perspective(12em) rotateX(50deg) rotate(0deg) scale(1);
    }
    50% {
      transform: perspective(12em) rotateX(50deg) rotate(180deg) scale(1.1);
    }
    100% {
      transform: perspective(12em) rotateX(50deg) rotate(360deg) scale(1);
    }
  }
}
.mixlogo2 {
  margin-left: -130px;
}
.mixbutton {
  width: 100%;
  margin-top: 70px;
}
.mixbutton a {
  
  border-radius: 20px;
  clear: both;
  text-transform: uppercase;
  width: auto;
  padding: 5px 10px;
  font-size: 24px;
  display: inline-block;
}
.steps{
  display:flex;
  flex-direction: row;
  align-items: stretch;
  &__wrapper {
    max-height: 100%;
    min-width: 100%;
    overflow: hidden;
  }
}
.step {
  min-width: 100%;
  max-width: 100%;
  padding: 100px 0;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.active{
    background: url('url:../img/mix-mainscreen-bg-new.png?as=webp') no-repeat center top;
    background-size: cover;
    align-items: center;
  }
  &:nth-child(-n + 4){
    padding-top: 200px;
    padding-bottom: 200px;
  }
}
.step.active .buttons{
  margin-top: 100px;
}
#step-1 {
  
}

.choose-slime {
  .slick-track {
    padding-top: 50px;
    padding-bottom: 100px;
  }
  .slick-slide {
    transition: all 0.3s;
    transform: scale(0.6);
  }
  .slick-slide{
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    &.prev, &.next {
      transform: scale(0.8);
      transition: all 0.3s;
      transform-origin: center left;
      // filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
    }
    &.next {
      transform-origin: center right;
    }
    &.slick-center{
      transform: scale(1);
      transition: all 0.3s;
      // filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.5));
      &::after {
        content: "";
        width: 70px;
        height: 70px;
        background-image: url('url:../img/game/arrow.png?as=webp');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        bottom: 0px;
        transform: translateY(100%) translateX(100%) rotate(8.67deg);
        transform-origin: center;
        animation: float-arrow 2.5s infinite cubic-bezier(0.37, 0, 0.63, 1);
      }
      @keyframes float-arrow {
        0%, 100% {
          transform: translateY(100%) translateX(100%) rotate(8.67deg);
        }
        25% {
          transform: translateY(70%) translateX(100%) rotate(8.67deg);
        }
        
      }
    }
  }
}
.choose-slime {
  img {
    max-width: 100%;
    margin: auto;
  }
  
}
#step-2, #step-3 {
  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px auto 0px;
    .btn:first-child {
      margin-right: 300px;
    }
  }
  
}
#step-3 {
  .step-grid {
    height: 477px;
  }
}
.step-grid {
  position: relative;
  &__slime {
    position: relative;
  }
}
.droppable-item{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &>img{
    position: absolute;
  }
  &>img:not(.choosen-ps){
    animation: added-item 1s;
  }
  // .draggable-item {
    
  // }
  @keyframes added-item {
    0%, 100% {
      transform: scale(1);
      opacity: 1;
    }
    30% {
      transform: scale(0.9);
      opacity: .5;
    }
    50% {
      transform: scale(1.1);
      opacity: .9;
    }
    80% {
      transform: scale(0.95);
      opacity: .7;
    }
  }
}
.draggable-item {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  &:hover{
    cursor: grab;
  }
  &.taken {
    transform: scale(1.4);
    cursor: grabbing;
  }
}
.step-grid{
  display: grid;
  width:100%;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas: 'a b c c d e'
                        'f g c c h i'
                        'j k c c l m';
  img{
    max-width: 100%;
  }
  &>div{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .step-grid__slime{
    grid-area: c;
    // background-image: url('url:../img/game/slime-lilac.png?as=webp');
    // background-size: contain;
    // background-repeat: no-repeat;
    // background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}
#step-3{
  .step-grid {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas: 'a b c c d e'
                          'f g c c h i';   
  }
}
.result-container {
  max-width: 700px;
  margin: 0 auto;
}
.mixed-item {
  padding: 0px 50px;
  margin-bottom: 50px;
  &__img {
    min-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img {
      transition: opacity .3s;
      max-height: 330px;
    }
    &::after {
      content: '';
      position: absolute;
      top: calc(50% - 15px);
      left: calc(50% - 15px);
      width: 30px;
      height: 30px;
      background-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M33 33L25.6807 25.6677L33 33ZM29.7368 15.8684C29.7368 19.5466 28.2757 23.074 25.6749 25.6749C23.074 28.2757 19.5466 29.7368 15.8684 29.7368C12.1903 29.7368 8.6628 28.2757 6.06197 25.6749C3.46113 23.074 2 19.5466 2 15.8684C2 12.1903 3.46113 8.6628 6.06197 6.06197C8.6628 3.46113 12.1903 2 15.8684 2C19.5466 2 23.074 3.46113 25.6749 6.06197C28.2757 8.6628 29.7368 12.1903 29.7368 15.8684V15.8684Z' stroke='%230F1F53' stroke-width='4' stroke-linecap='round'/%3E%3Cpath d='M15.8682 10.1577V15.8682M15.8682 15.8682V21.5788M15.8682 15.8682H21.5788M15.8682 15.8682H10.5' stroke='%230F1F53' stroke-width='4' stroke-linecap='square'/%3E%3C/svg%3E");
      background-size: contain;
      visibility: hidden;
      opacity: 0;
      transition: opacity .3s;
    }
    // &:hover {
    //   cursor: pointer;
    //   &>img {
    //     opacity: .5
    //   }
    //   &::after {
    //     visibility: visible;
    //     opacity: 1;
    //   }
    // }
  }
  &__info {
    margin-left: 40px;
    position: relative;
    overflow: hidden;
    &.links-opened {
      .markets-links{
          transform: translateY(0%);
      }
      .buy-btn{
          display: block;
          visibility: hidden;
      }
      .mixed-item__info__text{
        visibility: hidden;
        transform: translateY(-100%);
      }
    }
    &__text {
      font-size: 36px;
      // line-height: 43px;
      text-transform: uppercase;
      text-align: left;
      margin-bottom: 20px;
      transition: all 0.3s;
    }
  }
}
#step-4, #step-5 {
  background: url('url:../img/game/mix-result-bg.png?as=webp') no-repeat center;
  background-size: cover;
  padding-bottom: 50px;
  padding-top: 50px;
}
#step-5 {
  .mixed-item {
    padding: 0px;
  }
}
.additional-products{
  margin-bottom: 50px;
  .product {
    padding: 0px 10px;
  }
}
.slick.step-carousel {
  margin-top: 20px;
}
.images-modal {
  position: fixed;
  max-height: 700px;
  max-width: 580px;
  height: 975px;
  width: 1525px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  z-index: 11;
  background-color: #fff;
  border-radius: 60px;
  padding: 80px;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
  // overflow: hidden;
  visibility: hidden;
  transition: all 0.4s;
  &__content {
    position: static!important;
    .slick-prev {
      left: -60px;
    }
    .slick-next {
      right: -60px;
    }
  }
}
@media (max-width: 1600px) {
  .step {
    &:nth-child(-n + 4){
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }
  .mixlogo-wrapper::after {
    width: 210px;
    height: 207px;
  }
}
@media (max-width: 1200px) {
  .step-grid img {
    max-width: 80%;
  }
  #step-3 {
    .step-grid {
      height: auto;
    }
  }
}
@media (max-width: 850px) {
  .mixlogo-wrapper {
    max-width: 90%;
  }
  img.mixlogo {
    max-width: 500px;
  }
  img.mixlogo2{
    max-width: 200px;
  }
  .result-container {
    max-width: 100%;
  }
  .mixed-item__info__text {
    font-size: 24px;
  }
  .mixed-item__info {
    text-align: left;
  }
}
@media (min-width: 600px) {
  .slick.step-carousel {
    display: none;
  }
}
@media (max-width: 600px){
  .step-grid{
    display: block;
    &>div:not(.step-grid__slime) {
      display: none;
    }
  }
  #step-2 .buttons .btn:first-child, #step-3 .buttons .btn:first-child {
    margin-right: unset;
  }
  #step-2 .buttons, #step-3 .buttons {
    width: 90%;
  }
  // .choose-slime__item {
  //   padding: 30px;
  // }
  .mixlogo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  img.mixlogo {
    max-width: 100%;
  }
  img.mixlogo2 {
    margin-left: 0px;
    max-width: 120px;
  }
  .step.active .buttons {
    margin-top: 50px;
  }
  .choose-slime .slick-track {
    padding-top: 0px;
    padding-bottom: 50px;
  }
  .choose-slime .slick-prev, .choose-slime .slick-next {
    top: calc(50% - 25px);
  }
  .step-carousel{
    .slick-track {
      display: flex;
      align-items: center;
    }
    .slick-slide{
      padding: 10px;
    }
    
    
  }
  .choose-slime .slick-slide.slick-center::after {
    width: 50px;
    height: 50px;
  }
  .result-container{
    .slick-prev {
      left: 10px;
    }
    .slick-next {
      right: 10px;
    }
  }
}
@media (max-width: 430px){
  .droppable-item {
    width: 80%;
    margin: auto;
    top: unset;
    left: unset;
  }
  .mixlogo-wrapper::after {
    display: none;
  }
  .mixed-item__info {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .mixed-item__info__text {
    font-size: 16px;
  }
  .mixed-item__info {
    margin-left: 0px;
    padding-left: 0px;
  }
  .btn {
    font-size: 20px;
  }
  .mixed-item__info .btn {
    margin-bottom: 10px;
  }
}
@media (max-width: 360px) {

}