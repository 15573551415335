.modal.aqua, {
    .modal-content{
        background-color: #ffffff;
    }
    input, textarea{
        max-width: 100%;
        width: 100%;
        border: none;
        border-bottom: 2px solid rgba(41, 36, 86, 1);
        font-size: 18px;
        &:focus{
            border: none;
            border-bottom: 2px solid rgba(41, 36, 86, 1);
            outline: none;
        }
    }
    input{
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0px;
        }
    }
    textarea{
        height: 100%;
    }
    .form-footer{
        margin-top: 20px;
    }
    .checkbox{
        font-size: 12px;
        margin-bottom: 0px;
        input[type=checkbox]{
            display: none;
            &:checked+.checkbox-icon::after{
                display: block;
            }
        }
        @media (max-width: 768px) {
            margin-bottom: 16px;
        }
        .checkbox-icon{
            min-width: 26px;
            min-height: 26px;
            border: 3px solid #292456;
            box-sizing: border-box;
            border-radius: 50%;
            position: relative;
            margin-right: 10px;
            &::after{
                content: '';
                width: 14px;
                height: 14px;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                background-color: #292456;
                display: none;

            }
        }
        color:rgba(41, 36, 86, 1);
    }
    .close-modal {
        background-color: transparent;
        top: 20px;
        right: 20px;
    }
}
.response {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    max-width: 80%;
    top: 50%;
    background: #292456;
    margin: auto;
    transform: translateX(0%) translateY(-50%);
    left: 50%;
    color: white;
    padding: 30px;
    border-radius: 10px;
    transition: all .5s;
    &.show{
      visibility: visible;
      opacity: 1;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  .checkbox-icon.error {
      box-shadow: 0px 0px 5px 3px red;
}
.modal.aqua .modal-content {
    border-radius: 23px;
    box-shadow: 0 4px 15px rgba(0,0,0,.25);
    padding: 80px;
}
#subscribe-form {
    .checkbox {
        margin-bottom: 20px;
    }
    .modal-header {
        margin: 0px 0px 20px;
        padding: 0px;
        border-bottom: none;
    }
    .modal-title {
        color: #0f1f53;
        margin: 0px;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        @media (max-width: 400px) {
            font-size: 14px;
            line-height: 16px;
        }
    }
}
@media (min-width: 992px) {
    .modal-lg, .modal-xl {
        max-width: 860px;
    }
}
@media (max-width: 992px) {
    .modal.aqua .modal-content {
        border-radius: 23px;
        box-shadow: 0 4px 15px rgba(0,0,0,.25);
        padding: 50px;
    }
}
@media (max-width: 400px) {
    .modal.aqua input, .modal.aqua textarea {
        font-size: 16px;
    }
}