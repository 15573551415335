header {
width: 100%;
// height: 209px;
// background: url(:../img/header.png?as=webp) no-repeat top center;
position: fixed;
z-index: 2;
}
.logo {
width: 170px;
position: absolute;
left: 50%;
transform: translateX(-50%);
top: 5px;
transition: all 0.3s;
filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
z-index:30;
img{
  max-width: 100%;
}
}
.youtube {
position: absolute;
right: 5%;
top: 10px;
transition: all 0.3s;
filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
-webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
z-index:30;
}


.snot1{
position:absolute;
top:-160px;
left:calc(50% - 1290px);
z-index:27;
animation: 3s infinite shake 0.5s;
object-fit: cover;
}
.snot2{
position:absolute;
top:-160px;
left:calc(50% - 890px);
z-index:24;
animation: 3s infinite shake 1s;
object-fit: cover;
}
.snot3{
position:absolute;
top:-160px;
left:calc(50% - 535px);
z-index:25;
animation: 3s infinite shake 1.5s;
object-fit: cover;
}
.snot4{
position:absolute;
top:-160px;
left:calc(50% - 515px);
z-index:26;
animation: 3s infinite shake 2s;
object-fit: cover;
}
.snot5{
position:absolute;
top:-160px;
left:calc(50% - 210px);
z-index:28;
animation: 3s infinite shake 2.5s;
object-fit: cover;
}
.snot6{
position:absolute;
top:-160px;
left:calc(50% - 85px);
z-index:21;
animation: 3s infinite shake 3s;
object-fit: cover;
}
.snot7{
position:absolute;
top:-160px;
left:calc(50% + 380px);
z-index:23;
animation: 3s infinite shake 3.5s;
object-fit: cover;
}
.snot8{
position:absolute;
top:-160px;
left:calc(50% + 670px);
z-index:22;
animation: 3s infinite shake 4s;
object-fit: cover;
}
@keyframes shake {50% {transform: scale(102%);}}

.drop1{
position:absolute;
top:-30px;
left:calc(50% - 920px);
z-index:17;
width: 30px;
height:100px;
animation: 10s infinite dropdown 3s;
}
.drop2{
position:absolute;
top:-30px;
left:calc(50% - 720px);
z-index:14;
width: 30px;
height:100px;
animation: 10s infinite dropdown 6s;
}
.drop3{
position:absolute;
top:-60px;
left:calc(50% - 510px);
z-index:15;
width: 30px;
height:100px;
animation: 10s infinite dropdown 9s;
}
.drop4{
position:absolute;
top:-20px;
left:calc(50% - 250px);
z-index:16;
width: 30px;
height:100px;
animation: 10s infinite dropdown 12s;
}
.drop5{
position:absolute;
top:-20px;
left:calc(50% + 100px);
z-index:18;
width: 30px;
height:100px;
animation: 10s infinite dropdown 14s;
}
.drop6{
position:absolute;
top:-60px;
left:calc(50% + 360px);
z-index:10;
width: 30px;
height:100px;
animation: 10s infinite dropdown 16s;
}
.drop7{
position:absolute;
top:-10px;
left:calc(50% + 600px);
z-index:13;
width: 30px;
height:100px;
animation: 10s infinite dropdown 18s;
}
.drop8{
position:absolute;
top:-50px;
left:calc(50% + 860px);
z-index:12;
width: 30px;
height:100px;
animation: 10s infinite dropdown 20s;
}
@keyframes dropdown {50% {top: 0px;height:140px;opacity:0.8; width: 38px;}}









@media (max-width:500px){
  header {
    height: 166px;
    background-size: auto 100%;
    background-position: 56% 0;
  }
  .youtube {
    top: 5px;
    top: -180px;
  }
  .snot1{
    width: 427px;
    top: -180px;
    left:calc(50% - 1290px);
  }
  .snot2{
    width: 340px;
    top: -180px;
  }
  .snot3{
    width: 175px;
    top: -180px;
  }
  .snot4{
    width: 405px;
    top: -120px;
  }
  .snot5{
    width: 460px;
    top: -139px;
    left: calc(50% - 241px);
  }
  .snot6{
    width: 487px;
    top: -120px;
    left: calc(50% - 123px);
  }
  .snot7{
    width: 348px;
    top: -180px;
  }
  .snot8{
    width: 385px;
    top: -180px;
  }
  .drop5 {
    left: calc(50% + 20px);
  }
}