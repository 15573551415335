button {
    border: none;
    &:hover {
        cursor: pointer;
    }
}
.btn {
    border-radius: 25px;
    clear: both;
    text-transform: uppercase;
    width: auto;
    padding: 10px 15px;
    font-size: 24px;
    display: inline-block;
    &--primary {
        background: #fff;
        color: #0f1f53;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    }
    &--blue {
        background: #0f1f53;
        color: #fff;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
}
@media (max-width: 600px) {
    .btn {
        padding: 5px 10px;
    }
}