@import "custom";
@import "slick-carousel/slick/slick";

@import "buttons";
@import "header";
@font-face {
  font-family: "RotondaC";
  src: url(../fonts/RotondaC-Bold.eot);
  src: local(RotondaC),
    url(../fonts/RotondaC-Bold.eot?#iefix) format("embedded-opentype"),
    url(../fonts/RotondaC-Bold.woff) format("woff"),
    url(../fonts/RotondaC-Bold.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
* {
  box-sizing: border-box;
  font-family: RotondaC, Arial, sans-serif;
}
*:focus {
  outline: none;
}
html {
  scroll-behavior: smooth;
}
img {
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

body {
  margin: 0;
  padding: 0;
  font-family: RotondaC, Arial, sans-serif;
  font-size: 18px;
  color: #fff;
  background: #101c4f url('url:../img/head.png?as=webp') repeat top center;
  background-size: 100%;
  position: relative;
}
a {
  color: #fff;
  text-decoration: none;
}


.brands {
  width: 100%;
  margin: 0 auto;
  // padding: 0 2%;
  // padding-top: 230px;
  padding-top: 100px;
}
.slick-container-wrapper {
  width: 100%;
  position: relative;
}
.slick-container {
  max-width: 1600px;
  margin: auto;
  width: 100%;
}
// @media (max-width: 1500px){
//   .slick-container {
//     max-width: 1200px;
//   }
// }
// @media (max-width: 1280px){
//   .slick-container {
//     max-width: 1200px;
//   }
// }
.slick:not(.mixed-items, .additional-products) .slick-prev {
  left: 10px;
}
.slick:not(.mixed-items, .additional-products) .slick-next {
  right: 10px;
}
@media (min-width: 1600px) {
  .slick:not(.mixed-items, .additional-products, .choose-slime) {
    position: static;
  }
  .slick:not(.mixed-items, .additional-products) .slick-prev {
    left: 40px;
  }
  .slick:not(.mixed-items, .additional-products) .slick-next {
    right: 40px;
  }
}

.brand {
  position: relative;
  padding: 10px 0;
  transition: 0.3s;
  cursor: pointer;
}
.brand:hover {
  transform: scale(1.1);
}
.brand img {
  max-width: 100% !important;
  width: auto !important;
  margin: 0 auto;
}
.brand:after {
  content: "";
  position: absolute;
  width: 50%;
  height: 30%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  filter: blur(20px);
  z-index: -1;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.3s;
}
.brand:hover:after {
  opacity: 1;
}
.slick .slick-center .brand {
  transform: scale(1.1);
}
.slick .slick-center .brand:after {
  content: "";
  position: absolute;
  width: 50%;
  height: 30%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  filter: blur(20px);
  z-index: -1;
  border-radius: 50%;
  opacity: 1;
  transition: all 0.3s;
}
.crystal:after {
  background: #27b0ce;
}
.crunch:after {
  background: #ffd600;
}
.ninja:after {
  background: #44bd6d;
}
.butter:after {
  background: #ffd600;
}
.cream:after {
  background: #ffb6b1;
}
.jungle:after {
  background: #c34a00;
}
.cloud:after {
  background: #2e34c8;
}
.slime:after {
  background: #72bd44;
}
.clear:after {
  background: #ff9900;
}
.slick {
  padding: 0 50px;
  // cursor: grab;
}
.slick:active {
  padding: 0 50px;
  cursor: grabbing;
}
.slick-prev {
  z-index: 1;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background: url('url:../img/brands/left.png?as=webp') no-repeat center center !important;
  width: 39px;
  height: 39px;
  transition: 0.3s;
}
.slick-prev:hover {
  background: url('url:../img/brands/left-hover.png?as=webp') no-repeat center center !important;
  cursor: pointer;
}
.slick-next {
  z-index: 1;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background: url('url:../img/brands/right.png?as=webp') no-repeat center center !important;
  width: 39px;
  height: 39px;
  transition: 0.3s;
}
.slick-next:hover {
  background: url('url:../img/brands/right-hover.png?as=webp') no-repeat center center !important;
  cursor: pointer;
}
.slick:not(#sync2) .slick-slide {
  margin-right: 5px;
  margin-left: 5px;
}
.slick:not(#sync2) .slick-list
.videos .slick-prev {
  margin: 0 -5px;
}
.videos .slick-next {
  margin-right: 1.9%;
}
.videos {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.video {
  width: 100%;
  height: 726px;
  overflow: hidden;
}
.video video {
  width: 100%;
  min-width: 1920px;
  margin-left: 50%;
  transform: translateX(-50%);
}

#sync2 {
  padding: 0;
  .slick-next, .slick-prev {
    display: none!important;
  }
}
.soundwrap {
  position: absolute;
  top: 10px;
  left: 10px;
  opacity: 0.5;
}
@media (min-width: 1600px) {
  .soundwrap {
    top: 40px;
    left: 40px;
  }
}
.soundwrap:hover {
  cursor: pointer;
}
.soundwrap svg {
  display: block;
  position: relative;
  opacity: 0.5;
}

.volume {
  // --line: #fff;
  // --line-width: 8px;
  // --duration: 0.5s;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.volume-icon {
  width: 50px;
  height: 50px;
  background: url('url:../img/volume-off-icon.png?as=webp');
}
.volume input {
  display: none;
}
.volume input:checked + .volume-icon {
  background: url('url:../img/volume-on-icon.png?as=webp');
}
// .volume input + svg {
//   display: block;
//   fill: none;
//   stroke: var(--line);
//   stroke-linecap: round;
//   stroke-linejoin: round;
//   stroke-width: var(--line-width);
//   width: 45px;
//   height: 40px;
// }
// .volume input + svg path {
//   -webkit-animation: var(--name) var(--duration) ease forwards;
//   animation: var(--name) var(--duration) ease forwards;
// }
// .volume input + svg path:nth-child(2) {
//   stroke-dashoffset: 1px;
// }
// .volume input + svg path:nth-child(3) {
//   stroke-dashoffset: 1px;
// }
// .volume input:checked + svg path:nth-child(1) {
//   --name: shape-r;
// }
// .volume input:checked + svg path:nth-child(2) {
//   --name: small-r;
// }
// .volume input:checked + svg path:nth-child(3) {
//   --name: large-r;
// }
// .volume input:not(:checked) + svg path:nth-child(1) {
//   --name: shape;
// }
// .volume input:not(:checked) + svg path:nth-child(2) {
//   --name: small;
// }
// .volume input:not(:checked) + svg path:nth-child(3) {
//   --name: large;
// }
// @-webkit-keyframes small {
//   0%,
//   30% {
//     stroke-dasharray: 0 0 30px 64px;
//   }
//   40% {
//     stroke-dashoffset: 16px;
//   }
//   80%,
//   100% {
//     stroke-dashoffset: 1px;
//   }
//   70% {
//     stroke-dasharray: 0 43px 30px 64px;
//   }
//   100% {
//     stroke-dasharray: 0 39px 30px 64px;
//   }
// }
// @keyframes small {
//   0%,
//   30% {
//     stroke-dasharray: 0 0 30px 64px;
//   }
//   40% {
//     stroke-dashoffset: 16px;
//   }
//   80%,
//   100% {
//     stroke-dashoffset: 1px;
//   }
//   70% {
//     stroke-dasharray: 0 43px 30px 64px;
//   }
//   100% {
//     stroke-dasharray: 0 39px 30px 64px;
//   }
// }
// @-webkit-keyframes small-r {
//   0% {
//     stroke-dasharray: 0 39px 30px 64px;
//   }
//   0%,
//   40% {
//     stroke-dashoffset: 1px;
//   }
//   70% {
//     stroke-dashoffset: 16px;
//   }
//   70%,
//   100% {
//     stroke-dasharray: 0 0 30px 64px;
//   }
// }
// @keyframes small-r {
//   0% {
//     stroke-dasharray: 0 39px 30px 64px;
//   }
//   0%,
//   40% {
//     stroke-dashoffset: 1px;
//   }
//   70% {
//     stroke-dashoffset: 16px;
//   }
//   70%,
//   100% {
//     stroke-dasharray: 0 0 30px 64px;
//   }
// }
// @-webkit-keyframes large {
//   0%,
//   30% {
//     stroke-dasharray: 0 0 50px 84px;
//   }
//   40% {
//     stroke-dashoffset: 16px;
//   }
//   80%,
//   100% {
//     stroke-dashoffset: 1px;
//   }
//   70% {
//     stroke-dasharray: 0 82px 32px 84px;
//   }
//   100% {
//     stroke-dasharray: 0 78px 32px 84px;
//   }
// }
// @keyframes large {
//   0%,
//   30% {
//     stroke-dasharray: 0 0 50px 84px;
//   }
//   40% {
//     stroke-dashoffset: 16px;
//   }
//   80%,
//   100% {
//     stroke-dashoffset: 1px;
//   }
//   70% {
//     stroke-dasharray: 0 82px 32px 84px;
//   }
//   100% {
//     stroke-dasharray: 0 78px 32px 84px;
//   }
// }
// @-webkit-keyframes large-r {
//   0% {
//     stroke-dasharray: 0 78px 32px 84px;
//   }
//   0%,
//   40% {
//     stroke-dashoffset: 1px;
//   }
//   70% {
//     stroke-dashoffset: 16px;
//   }
//   70%,
//   100% {
//     stroke-dasharray: 0 0 50px 84px;
//   }
// }
// @keyframes large-r {
//   0% {
//     stroke-dasharray: 0 78px 32px 84px;
//   }
//   0%,
//   40% {
//     stroke-dashoffset: 1px;
//   }
//   70% {
//     stroke-dashoffset: 16px;
//   }
//   70%,
//   100% {
//     stroke-dasharray: 0 0 50px 84px;
//   }
// }
// @-webkit-keyframes shape {
//   0% {
//     stroke-dasharray: 60px 0 184px;
//     stroke-dashoffset: 0;
//   }
//   70% {
//     stroke-dasharray: 63px 51px 184px;
//     stroke-dashoffset: 21px;
//   }
//   100% {
//     stroke-dasharray: 59px 47px 184px;
//     stroke-dashoffset: 17px;
//   }
// }
// @keyframes shape {
//   0% {
//     stroke-dasharray: 60px 0 184px;
//     stroke-dashoffset: 0;
//   }
//   70% {
//     stroke-dasharray: 63px 51px 184px;
//     stroke-dashoffset: 21px;
//   }
//   100% {
//     stroke-dasharray: 59px 47px 184px;
//     stroke-dashoffset: 17px;
//   }
// }
// @-webkit-keyframes shape-r {
//   0% {
//     stroke-dasharray: 59px 47px 184px;
//     stroke-dashoffset: 17px;
//   }
//   100% {
//     stroke-dasharray: 60px 0 184px;
//     stroke-dashoffset: 0;
//   }
// }
// @keyframes shape-r {
//   0% {
//     stroke-dasharray: 59px 47px 184px;
//     stroke-dashoffset: 17px;
//   }
//   100% {
//     stroke-dasharray: 60px 0 184px;
//     stroke-dashoffset: 0;
//   }
// }

.slimerblock {
  width: 100%;
  height: auto;
  padding: 200px 0;
  text-align: center;
}
.slimertext {
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 70px;
}
.slimermixtext br {
  display: none;
}
.products {
  width: 100%;
  margin: 0 auto;
  // padding: 0 5%;
}
.products .slick-track {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
}
.product {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 0;
  // overflow: hidden;
}
.product>img {
  max-width: 100% !important;
  width: auto !important;
  margin: 0 auto;
  transition: opacity .3s;
}
.product>span {
  display: block;
  text-align: center;
  font-size: 24px;
  margin-top: 20px;
  transition: opacity .3s;
}
.product .buttons-overlay {
  // background-color: rgba(15, 31, 83, 0.5);
}
.additional-products .product .buttons-overlay {
  background-color: transparent;
}
.additional-products .product:hover>img{
  opacity: 0.5;
}
.product:hover .buttons-overlay{
  top: 0%;
  opacity: 1;
}
.product:hover>img, .product:hover>span {
  opacity: 0.5;
}
// .product:hover{opacity:0.5}
// .product:before{content:'';position:absolute;width:100%;height:100%;top:50%;left:50%;transform:translateX(-50%) translateY(-50%);z-index:2;opacity:0;transition:all 0.3s}
// .product:hover:before{opacity:1}
//  .product:after{content:'Смотреть';position:absolute;top:50%;left:50%;transform:translateX(-50%) translateY(-50%);z-index:3;background:#fff;color:#0F1F53;border-radius:20px;opacity:0;transition:all 0.3s;text-transform:uppercase;padding:5px 10px}
// .product:hover:after {
//   opacity: 1;
// }

@import './mixblock';

.labsblock {
  width: 100%;
  height: auto;
  padding: 200px 0;
}
.labstext {
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 70px;
}
.labstext br {
  display: none;
}
.labstypes-wrapper {
  padding: 0 5%;
}
.labstypes {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 100px;
  
}
.labstypes .labstype {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 0;
  margin: 0 10px;
  text-align: center;
  @media (min-width: 1400px) {
    min-width: 135px;
  }

}
.labstypes .labstype img {
  margin: auto;
  max-width: 100%;
  // transition: all 0.3s;
}
.labstypes .labstype span {
  white-space: nowrap;
}
// .labstypes .labstype:hover img{
//   transform: translateY(-20px);
// }
.labs {
  width: 100%;
  margin: 0 auto;
  // padding: 0 5%;
}
.labs .slick-track {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
}
.lab {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 0;
  overflow: hidden;
}
// .labs .lab .buttons-overlay {
//   background-color: rgba(15, 31, 83, 0.5);
// }
.labs .lab:hover .buttons-overlay{
  top: 0%;
  opacity: 1;
}
.labs .lab>img, .labs .lab>span {
  opacity: 1;
  transition: opacity .3s;
}
.labs .lab:hover>img, .labs .lab:hover>span{
  opacity: 0.5;
}
.lab img {
  max-width: 100% !important;
  width: auto !important;
  margin: 0 auto;
}
.lab span {
  display: block;
  text-align: center;
  font-size: 22px;
  margin-top: 20px;
}
/*.lab:hover{opacity:0.5}
.lab:before{content:'';position:absolute;width:100%;height:100%;top:50%;left:50%;transform:translateX(-50%) translateY(-50%);z-index:2;opacity:0;transition:all 0.3s}
.lab:hover:before{opacity:1}
 .lab:after{content:'Смотреть';position:absolute;top:50%;left:50%;transform:translateX(-50%) translateY(-50%);z-index:3;background:#fff;color:#0F1F53;border-radius:20px;opacity:0;transition:all 0.3s;text-transform:uppercase;padding:5px 10px} */
.lab:hover:after {
  opacity: 1;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.section {
  height: 100vh;
  padding-top: 266px;
  overflow: hidden;
  position: relative;
  background: #98dbef;
  // background: #98dbef url('url:../img/aqua/rays.png?as=webp');
  background-size: cover;
  background-position: 50% 100%;
  background-repeat: no-repeat;
}
.bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  margin: 0;
}
.bubbles>li {
  position: absolute;
  list-style: none;
  display: block;
  text-align: center;
  width: 100px;
  padding: 10px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid rgba(25, 136, 146, 0.2);
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: inset -3px 1px 10px rgba(37, 47, 141, 0.5), inset 0 0 35px rgba(50, 100, 100, 0.2);
  -webkit-animation: float 25s infinite;
  animation: float 25s infinite;
  transition-timing-function: linear;
  bottom: -160px;
  // filter: blur(1px);
}
.bubbles>li:after {
  display: block;
  content: "";
  height: 8px;
  width: 11px;
  transform: rotate(40deg);
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  position: absolute;
  top: 20%;
  right: 20%;
}
.bubbles>li:before {
  display: block;
  content: "";
  height: 15px;
  width: 20px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  position: absolute;
  bottom: 20%;
  left: 20%;
  transform: rotate(40deg);
  
}
.bubbles>li:nth-child(1) {
  left: 10%;
}
.bubbles>li:nth-child(2) {
  left: 20%;
  transform: scale(0.5, 0.5);
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 17s;
          animation-duration: 17s;
  
}
.bubbles>li:nth-child(3) {
  left: 25%;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}
.bubbles>li:nth-child(4) {
  left: 40%;
  transform: scale(0.3, 0.3);
  -webkit-animation-duration: 22s;
          animation-duration: 22s;
  background-color: rgba(255, 255, 255, 0.25);
}
.bubbles>li:nth-child(5) {
  left: 70%;
  transform: scale(0.4, 0.4);
}
.bubbles>li:nth-child(6) {
  left: 80%;
  transform: scale(0.6, 0.6);
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
  background-color: rgba(255, 255, 255, 0.1);
}
.bubbles>li:nth-child(7) {
  left: 32%;
  transform: scale(0.8, 0.8);
  -webkit-animation-delay: 7s;
          animation-delay: 7s;
}
.bubbles>li:nth-child(8) {
  left: 55%;
  transform: scale(0.2, 0.2);
  -webkit-animation-delay: 15s;
          animation-delay: 15s;
  -webkit-animation-duration: 40s;
          animation-duration: 40s;
}
.bubbles>li:nth-child(9) {
  left: 25%;
  transform: scale(0.1, 0.1);
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 40s;
          animation-duration: 40s;
}
.bubbles>li:nth-child(10) {
  left: 85%;
  transform: scale(1, 1);
  -webkit-animation-delay: 11s;
          animation-delay: 11s;
}
.bubbles>li:nth-child(11) {
  left: 85%;
  transform: scale(0.5, 0.5);
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.bubbles>li:nth-child(12) {
  left: 45%;
  transform: scale(0.4, 0.4);
  -webkit-animation-delay: 6s;
          animation-delay: 6s;
  -webkit-animation-duration: 40s;
          animation-duration: 40s;
}
.bubbles>li:nth-child(13) {
  left: 15%;
  transform: scale(0.6, 0.6);
  -webkit-animation-delay: 7s;
          animation-delay: 7s;
}
.bubbles>li:nth-child(14) {
  left: 15%;
  transform: scale(0.9, 0.9);
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  -webkit-animation-duration: 15s;
          animation-duration: 15s;
}
.bubbles>li:nth-child(15) {
  left: 86%;
  transform: scale(0.3, 0.3);
  -webkit-animation-delay: 20s;
          animation-delay: 20s;
  -webkit-animation-duration: 22s;
          animation-duration: 22s;
}
.bubbles>li:nth-child(16) {
  left: 34%;
  transform: scale(1, 1);
  -webkit-animation-delay: 7s;
          animation-delay: 7s;
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
}
.bubbles>li:nth-child(17) {
  left: 2%;
  transform: scale(0.7, 0.7);
  -webkit-animation-delay: 32s;
          animation-delay: 32s;
  -webkit-animation-duration: 11s;
          animation-duration: 11s;
}
.bubbles>li:nth-child(18) {
  left: 73%;
  transform: scale(0.4, 0.4);
  -webkit-animation-delay: 14s;
          animation-delay: 14s;
  -webkit-animation-duration: 14s;
          animation-duration: 14s;
}
.bubbles>li:nth-child(19) {
  left: 59%;
  transform: scale(0.8, 0.8);
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
}
.bubbles>li:nth-child(20) {
  left: 11%;
  transform: scale(0.3, 0.3);
  -webkit-animation-delay: 86s;
          animation-delay: 86s;
  -webkit-animation-duration: 28s;
          animation-duration: 28s;
}
.bubbles>li:nth-child(21) {
  left: 44%;
  transform: scale(0.9, 0.9);
  -webkit-animation-delay: 48s;
          animation-delay: 48s;
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
}
.bubbles>li:nth-child(22) {
  left: 93%;
  transform: scale(0.7, 0.7);
  -webkit-animation-delay: 32s;
          animation-delay: 32s;
  -webkit-animation-duration: 21s;
          animation-duration: 21s;
}
.bubbles>li:nth-child(23) {
  left: 31%;
  transform: scale(0.5, 0.5);
  -webkit-animation-delay: 60s;
          animation-delay: 60s;
  -webkit-animation-duration: 7s;
          animation-duration: 7s;
}
.bubbles>li:nth-child(24) {
  left: 69%;
  transform: scale(0.3, 0.3);
  -webkit-animation-delay: 48s;
          animation-delay: 8s;
  -webkit-animation-duration: 19s;
          animation-duration: 19s;
}

@-webkit-keyframes float {
  0% {
    bottom: -200px;
    margin-left: 0;
  }

  5% {
    margin-left: 0;
  }

  10% {
    margin-left: 10px;
  }

  15% {
    margin-left: 0;
  }

  20% {
    margin-left: 15px;
  }

  25% {
    margin-left: 0;
  }

  30% {
    margin-left: 10px;
  }

  35% {
    margin-left: 0;
  }

  40% {
    margin-left: 10px;
  }

  45% {
    margin-left: 0;
  }

  50% {
    margin-left: 20px;
  }

  55% {
    margin-left: 0;
  }

  60% {
    margin-left: 10px;
  }

  65% {
    margin-left: 0;
  }

  70% {
    margin-left: 10px;
  }

  75% {
    margin-left: 0;
  }

  80% {
    margin-left: 15px;
  }

  85% {
    margin-left: 0;
  }

  90% {
    margin-left: 10px;
  }

  95% {
    margin-left: 0;
  }

  100% {
    bottom: 120%;
    margin-left: 0;
  }
}


@keyframes float {
  0% {
    bottom: -120px;
    margin-left: 0;
  }

  5% {
    margin-left: 0;
  }

  10% {
    margin-left: 10px;
  }

  15% {
    margin-left: 0;
  }

  20% {
    margin-left: 15px;
  }

  25% {
    margin-left: 0;
  }

  30% {
    margin-left: 10px;
  }

  35% {
    margin-left: 0;
  }

  40% {
    margin-left: 10px;
  }

  45% {
    margin-left: 0;
  }

  50% {
    margin-left: 20px;
  }

  55% {
    margin-left: 0;
  }

  60% {
    margin-left: 10px;
  }

  65% {
    margin-left: 0;
  }

  70% {
    margin-left: 10px;
  }

  75% {
    margin-left: 0;
  }

  80% {
    margin-left: 15px;
  }

  85% {
    margin-left: 0;
  }

  90% {
    margin-left: 10px;
  }

  95% {
    margin-left: 0;
  }

  100% {
    bottom: 110%;
    margin-left: 0;
  }
}
@media (orientation: portrait), (max-width: 1400px) {
  .section {
    height: auto;
  }
  .section:not(.section-1) {
    padding: 200px 0;
  }
}
@media (max-width: 992px) {
  .section:not(.section-1) {
    padding: 100px 0;
  }
}
.section_content {
  height: 100%;
  position: relative;
  z-index: 1;
  padding: 20px 0 0;
}
@media (max-width: 768px) {
  .section_content {
    padding: 20px 25px 0;
  }
}
.img-fluid {
  max-width: 100%;
  max-height: 100%;
  width: auto;
}
.section_bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.floating-animate {
  -webkit-animation: floating 6s ease-in-out infinite;
  animation: floating 6s ease-in-out infinite;
}
@keyframes floating_cat {
  0% {
    transform: translateX(-5%) translateY(-5%);
  }
}
.section-1 {
  position: relative;
  z-index: 1;
}
// @media (min-height: 675px) and (max-height: 812px) {
//   .section-1 .section_content {
//     padding-bottom: 50px;
//   }
// }
.section-1 .row {
  height: 100%;
  position: relative;
}
.section-1 .row .col-sm-6 {
  max-height: 100%;
}
.section-1 .row .img-fluid {
  max-height: 100%;
  max-width: 100%;
}
.section-1 .row .img-fluid.main-text {
  max-height: 32%;
}
.section-1 .row .img-fluid.main-aquarium {
  max-height: calc(100% - 32%);
  position: relative;
  left: 14%;
}
.section-1 .row .img-fluid.main-right {
  position: relative;
  top: -5%;
  right: 5%;
}
.section-1 .star {
  position: absolute;
  width: 71px;
  height: 71px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
@media (max-width: 1200px) {
  .section-1 .star {
    width: 40px;
    height: 40px;
  }
}
.section-1 .star:nth-child(1) {
  top: 15%;
  left: -1%;
  background-image: url('url:../img/aqua/blue-star-1.png?as=webp');
}
.section-1 .star:nth-child(2) {
  top: 83%;
  left: 3%;
  background-image: url('url:../img/aqua/yellow-star-1.png?as=webp');
}
.section-1 .star:nth-child(3) {
  top: 61%;
  left: 7%;
  background-image: url('url:../img/aqua/blue-star-2.png?as=webp');
}
.section-1 .star:nth-child(4) {
  top: 28%;
  left: 13%;
  background-image: url('url:../img/aqua/yellow-star-2.png?as=webp');
}
.section-1 .star:nth-child(5) {
  top: 70%;
  left: 19%;
  background-image: url('url:../img/aqua/yellow-star-3.png?as=webp');
}
.section-1 .star:nth-child(6) {
  top: 12%;
  left: 78%;
  background-image: url('url:../img/aqua/yellow-star-4.png?as=webp');
}
.section-1 .star:nth-child(7) {
  top: 82%;
  left: 82%;
  background-image: url('url:../img/aqua/blue-star-3.png?as=webp');
}
.section-1 .star:nth-child(8) {
  top: 44%;
  left: 89%;
  background-image: url('url:../img/aqua/yellow-star-5.png?as=webp');
}
.section-1 .star:nth-child(9) {
  top: 5%;
  left: 97%;
  background-image: url('url:../img/aqua/blue-star-4.png?as=webp');
}
.section-1 .star:nth-child(10) {
  top: 76%;
  left: 98%;
  background-image: url('url:../img/aqua/yellow-star-6.png?as=webp');
}
@media (max-width: 576px) {
  .section-1 {
    height: 100vh;
  }
  .section-1 .col-sm-6.text-right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .section-1 .col-sm-6.text-right .main-aquarium {
    max-height: 38%;
    transform: translateX(10%);
    left: unset;
  }
  .section-1 .col-sm-6.text-right .main-text {
    max-height: 19%;
    transform: translateX(-25%);
  }
  .section-1 .col-sm-6.text-left {
    position: absolute;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .section-1 .col-sm-6.text-left .img-fluid.main-right {
    position: absolute;
    max-height: 61%;
    z-index: -1;
    top: unset;
    bottom: 19%;
    right: unset;
  }
}

.aqualogo {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
}
.aqualogo img {
  max-width: 100%;
  width: auto;
}
.planetblock {
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.planetblock span {
  color: rgba(102, 36, 131, 1);
  white-space: nowrap;
  font-size: 24px;
}
.planeta {
  transition: all 0.3s;
}
.planeta:hover {
  filter: drop-shadow(0px 0px 20px rgba(255, 214, 0, 0.25));
  -webkit-filter: drop-shadow(0px 0px 20px rgba(255, 214, 0, 0.25));
}
.strelka {
  animation: floating 3s ease-in-out infinite;
}
@keyframes floating {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

footer {
  width: 100%;
  text-align: center;
  padding: 280px 0px 0px;
}
.footerphone {
  font-size: 36px;
}
.footertext {
  font-size: 24px;
}
.footerbutton {
  width: 100%;
  margin-top: 40px;
}
.footerbutton a {
  background: #fff;
  color: #0f1f53;
  border-radius: 20px;
  clear: both;
  text-transform: uppercase;
  width: auto;
  padding: 5px 10px;
  font-size: 24px;
}
.footersocial {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 80px;
}
.footersocial a {
  margin: 0 50px;
}
.footer-header {
  font-size: 36px;
  line-height: 43px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 80px;
}
.footer-slimes{
  position: absolute;
  left: 0px;
  width: 100%;
  img{
    max-width: 100%;
    height: auto;
    display: block;
  
  }
}

@media (max-width: 768px) {
  .soundwrap {
    left: 3%;
  }
  .strelka {
    width: 40px;
  }
  .planeta {
    width: 120px;
  }
  .labstypes .labtype {
    font-size: 18px;
  }
  .lab span {
    font-size: 18px;
  }
  .brandsblock {
    background-size: cover;
  }
  .slimerblock {
    background-size: cover;
  }
  footer {
    padding: 100px 5% 0px;
  }
}
@media (max-width: 576px) {
  .strelka {
    width: 20px;
  }
  .planeta {
    width: 80px;
  }
  .section.section-1 {
    padding-top: 150px;
  }
  .section.section-1 .section_content {
    padding-bottom: 100px;
  }
}
@media (max-width: 500px) {
  header {
    height: 166px;
    background-size: auto 100%;
    background-position: 56% 0;
  }
  .logo {
    left: 30%;
    width: 150px;
  }
  .logo img {
    width: 100%;
  }
  .youtube {
    right: 10%;
    top: 5px;
    width: 50px;
  }
  .youtube img {
    width: 100%;
  }
  
  .video {
    max-height: 500px;
  }
  .video video {
    min-width: 1322px;
  }
  .slimerlogo {
    max-width: 260px;
  }
  .slimerblock,  .labsblock{
    padding: 120px 0px;
  }
  .slimertext {
    font-size: 26px;
  }
  .mixtext br {
    display: initial;
  }
  .mixlogo {
    max-width: 260px;
  }
  .mixlogo2 {
    max-width: 120px;
    // margin-left: -50px;
  }
  .mixtext {
    font-size: 26px;
  }
  .labstext {
    font-size: 26px;
  }
  .labstext br {
    display: initial;
  }
  .text {
    width: 200px;
  }
  .footer-header, .footerphone {
    font-size: 24px;
    line-height: 28px;
  }
  .footertext {
    font-size: 20px;
  }
  .footer-slimes{
    overflow: hidden;
    img{
      height: 92px;
      width: auto;
      max-width: unset;
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }
}
@media (max-width: 375.9px) {
  .video {
    width: 100%;
    overflow: hidden;
  }
  .video video {
    width: 100%;
    min-width: unset;
    margin-left: 0px;
    transform: unset;
  }
}
@import './shop';
@import './contact-form';

ul.rays  {
  position: absolute;
  left: -10em;
  right: -10em;
  top: -100%;
  bottom:-300px;
  margin: auto;
  padding: 0;
  width: 0;
  height: 0;
  list-style: none;
 opacity:.2;
    filter:blur(6px);
}
ul.rays.left {
    animation: 40s rotate-l infinite linear; 
}

ul.rays.right {
    animation: 30s rotate-r infinite linear; 
}

.rays > li,
.rays > li:before,
.rays > li:after {

  position: absolute;
  border: 0 solid transparent;
  border-width: 1.2em 24em;
  border-color: transparent rgba(255,255,255, 1);
  width: 0;
  height: 0;
  font-size: 60px;
  }
.rays > li {
  left: -24em;
  top: 40%;
  margin-top: -1.2em;
  transform: rotate(.1deg);
  }
.rays > li:before,
.rays > li:after {
  left: -24em;
  top: -1.2em;
  display: block;
  content: "";
}

.rays > li:before {
  transform: rotate(60deg);
  }
.rays > li:after {
  transform: rotate(-60deg);
  }

.rays > li:nth-child(2){
  transform: rotate(12deg);
  }
.rays > li:nth-child(2),
.rays > li:nth-child(2):before,
.rays > li:nth-child(2):after {
  border-color: transparent rgba(255,255,255, 1);
  }
.rays > li:nth-child(3){
  transform: rotate(37deg);
  }
.rays > li:nth-child(3),
.rays > li:nth-child(3):before,
LI:nth-child(3):after {
  border-color: transparent rgba(230, 247, 255, 1);
  }
.rays > li:nth-child(4){
  transform: rotate(55deg);
  }
.rays > li:nth-child(4),
.rays > li:nth-child(4):before,
.rays > li:nth-child(4):after {
  border-color: transparent rgba(255,255,255, 1);
  }


@keyframes rotate-l {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate-r {
  100% {
    transform: rotate(-360deg);
  }
}

.rays:before {
  background: conic-gradient(rgba(255, 255, 255, 0.42), rgba(255, 255, 255, 0.45), rgba(255, 255, 255, 0.69), rgba(87, 199, 255, 0.01), rgba(230, 247, 255, 0.22), rgba(240, 250, 255, 0.11), rgba(255, 255, 255, 0.53), rgba(255, 255, 255, 0.97), rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.73), rgba(255, 255, 255, 0.77), rgba(133, 214, 255, 0.35), rgba(148, 219, 255, 0.99), rgba(255, 255, 255, 0.7), rgba(250, 253, 255, 0.66), rgba(240, 250, 255, 0.07), rgba(255, 255, 255, 0.49), rgba(26, 179, 255, 0.39), rgba(230, 247, 255, 0.57), rgba(255, 255, 255, 0.27), rgba(82, 197, 255, 0.73), white, rgba(51, 187, 255, 0.53), rgba(255, 255, 255, 0.42), rgba(255, 255, 255, 0.45), rgba(255, 255, 255, 0.69), rgba(87, 199, 255, 0.01), rgba(230, 247, 255, 0.22), rgba(240, 250, 255, 0.11), rgba(255, 255, 255, 0.53), rgba(255, 255, 255, 0.97), rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.73), rgba(255, 255, 255, 0.77), rgba(133, 214, 255, 0.35), rgba(148, 219, 255, 0.99), rgba(255, 255, 255, 0.7), rgba(250, 253, 255, 0.66), rgba(240, 250, 255, 0.07), rgba(255, 255, 255, 0.49), rgba(26, 179, 255, 0.39), rgba(230, 247, 255, 0.57), rgba(255, 255, 255, 0.27), rgba(82, 197, 255, 0.73), white);
}
.rays:after {
  background: conic-gradient(rgba(255, 255, 255, 0.11), rgba(10, 173, 255, 0.91), rgba(255, 255, 255, 0.41), rgba(255, 255, 255, 0.81), rgba(255, 255, 255, 0.52), rgba(245, 252, 255, 0.38), rgba(204, 238, 255, 0.04), rgba(56, 189, 255, 0.61), rgba(128, 213, 255, 0.74), rgba(255, 255, 255, 0.92), rgba(214, 241, 255, 0.29), rgba(41, 184, 255, 0.9), rgba(255, 255, 255, 0.49), rgba(255, 255, 255, 0.29), rgba(199, 236, 255, 0.48), rgba(138, 216, 255, 0.41), rgba(255, 255, 255, 0.37), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.09), rgba(5, 172, 255, 0.95), rgba(255, 255, 255, 0.49), rgba(255, 255, 255, 0.91), rgba(255, 255, 255, 0.11), rgba(10, 173, 255, 0.91), rgba(255, 255, 255, 0.41), rgba(255, 255, 255, 0.81), rgba(255, 255, 255, 0.52), rgba(245, 252, 255, 0.38), rgba(204, 238, 255, 0.04), rgba(56, 189, 255, 0.61), rgba(128, 213, 255, 0.74), rgba(255, 255, 255, 0.92), rgba(214, 241, 255, 0.29), rgba(41, 184, 255, 0.9), rgba(255, 255, 255, 0.49), rgba(255, 255, 255, 0.29), rgba(199, 236, 255, 0.48), rgba(138, 216, 255, 0.41), rgba(255, 255, 255, 0.37), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.09), rgba(5, 172, 255, 0.95), rgba(255, 255, 255, 0.49));
  animation-duration: 3.6s;
  animation-direction: reverse;
}

@keyframes rot {
  to {
    transform: rotate(1turn);
  }
}
@media (max-width: 400px) {
  ul.rays {
    bottom: -200px;
  }
}
html {
  height: 100%;
}

body.taplink-page {
  min-height: 100%;
  height: 100%;
}
.taplinks-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}
.taplinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  .btn {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 10px;
      max-height: 30px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
@media (max-width: 600px) {
  .taplinks {
    .btn {
      font-size: 12px;
      margin-bottom: 20px;
      padding: 15px 10px;
      img {
        margin-right: 10px;
        max-height: 20px;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

.grecaptcha-badge { visibility: hidden; }